import { PocOrcamento } from 'core/models/POC';
import { SendToClickSignEnergia } from 'core/services/POC/pocOrcamento';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Loader,
  Modal,
  Notification,
  RadioButton,
} from 'ui/components';
import { ResponseStatus, Theme } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: PocOrcamento;
  setData: Dispatch<SetStateAction<PocOrcamento>>;
  documentsGridView: any;
  itemsGridView: any;
}

const authButtons = [
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'SMS',
    value: 'sms',
  },
  {
    text: 'WhatsApp',
    value: 'whatsapp',
  },
];

export default function ModalSendToCLickSign({
  isOpen,
  setIsOpen,
  data,
  setData,
  documentsGridView,
  itemsGridView,
}: IProps) {
  const [message, setMessage] = useState<Message | null>(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const onSetMessage = (status: number, msg: string) => {
    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const handleSendToClickSign = async () => {
    setLoading(true);

    if (!data.auth || data.auth === '') {
      setMessage({
        message: 'É necessário selecionar o modo de envio do contrato',
        theme: Theme.Warning,
      });

      setLoading(false);

      return;
    }

    const {
      message: msg,
      status,
      data: pocorcamento,
    } = await SendToClickSignEnergia(data);

    if (status === ResponseStatus.Success) {
      setData(pocorcamento);
      itemsGridView.current.setDataSource(pocorcamento.itens);
      documentsGridView.current.setDataSource(pocorcamento.documentos);
    }

    onSetMessage(status, msg);

    setLoading(false);
  };

  return (
    // @ts-expect-error
    <Modal
      title='Enviar documentos ao ClickSign'
      onClose={() => setIsOpen(false)}
      show={isOpen}
    >
      <Loader loading={loading} />
      {/* @ts-expect-error */}
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              floatable
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='mb-3'>
          {/* @ts-expect-error */}
          <RadioButton
            label='Selecionar modo de envio para assinatura'
            theme={Theme.Primary}
            outline
            buttons={authButtons}
            selectedButton={data.auth}
            onChange={(auth: string) => {
              setData({
                ...data,
                auth,
              });
            }}
          />
        </div>

        <div className='mb-3'>
          <Button
            text='Enviar'
            theme={Theme.Primary}
            onClick={() => handleSendToClickSign()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
