/* eslint-disable import/extensions */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  deleteEnergiaUnidadeConsumidora,
  getEnergiaUnidadeConsumidora,
  saveEnergiaUnidadeConsumidora,
} from 'core/services/ENE/energiaUnidadeConsumidora';
import { getClienteAutoComplete } from 'core/services/FIN';
import { getEnergiaDistribuidoraAutoComplete } from 'core/services/ENE/energiaDistribuidora';
import { getEnergiaTipoTarifaAutoComplete } from 'core/services/ENE/energiaTipoTarifa';
import { getEnergiaContaPagined } from 'core/services/ENE/energiaConta';
import { getCidadeEstadoAutoComplete } from 'core/services/SEG';
import { getEnergiaContaConsumoList } from 'core/services/ENE/energiaContaConsumo';
import EnergiaUnidadeConsumidora from 'core/models/ENE/energiaUnidadeConsumidora';
import { Cliente } from 'core/models/FIN';
import EnergiaDistribuidora from 'core/models/ENE/energiaDistribuidora';
import EnergiaTipoTarifa from 'core/models/ENE/energiaTipoTarifa';
import EnergiaConta from 'core/models/ENE/energiaConta';
import { Cidade } from 'core/models/SEG';
import {
  Autocomplete,
  CSDManutPage,
  GridView,
  Panel,
  RadioButton,
  Textbox,
} from 'ui/components';
import {
  BootstrapSizes,
  ColumnDataTypes,
  ColumnTypes,
  MaskTypes,
  Theme,
} from 'ui/Helpers/enums';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { PageTypes, ResponseStatus } from 'ui/Helpers/utils';
import { ModalDetalhamentoConsumoFatura } from './modalDetalhamentoConsumoFatura';

const tiposFornecimento = [
  {
    text: 'Monofásico',
    value: 'MON',
  },
  {
    text: 'Bifásico',
    value: 'BI',
  },
  {
    text: 'Trifásico',
    value: 'TRI',
  },
];

export default function EnergiaUnidadeConsumidoraItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();
  const gridViewContas: any = useRef();
  const gridViewConsumos: any = useRef();

  const [data, setData] = useState<EnergiaUnidadeConsumidora | {}>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [filtros, setFiltros] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);

  const searchConsumos = async (nrSeqEnergiaConta: number): Promise<void> => {
    setShowModal(true);

    setLoadingModal(true);

    const { data: consumos } = await getEnergiaContaConsumoList({
      nrSeqEnergiaConta,
    });

    if (gridViewConsumos && gridViewConsumos.current) {
      gridViewConsumos.current.setDataSource(consumos);
    }

    setLoadingModal(false);
  };

  const columnsContas: GridviewColumns[] = [
    { key: 'anoMesRefFormated', title: 'Ano/Mês Ref.', sortKey: 'anoMesRef' },
    { key: 'qtdDiasConsumo', title: 'Qtd. Dias Consumo' },
    { key: 'vlrConta', title: 'Valor', format: ColumnDataTypes.Money },
    {
      key: 'dtVencimento',
      title: 'Dt. Vencimento',
      format: ColumnDataTypes.Date,
    },
    { key: 'dtLeitura', title: 'Dt. Leitura', format: ColumnDataTypes.Date },
    {
      key: 'dtLeituraAnterior',
      title: 'Dt. Leitura Anterior',
      format: ColumnDataTypes.Date,
    },
    {
      key: 'nrSeqEnergiaConta',
      title: 'Consumo',
      type: ColumnTypes.Button,
      icon: 'search',
      theme: Theme.Primary,
      size: BootstrapSizes.ExtraSmall,
      onClick: ({ nrSeqEnergiaConta }: EnergiaConta) => {
        searchConsumos(nrSeqEnergiaConta);
      },
      sortable: false,
    },
  ];

  const onSetMessage = (status: number, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchContas = async (filters: any): Promise<void> => {
    if (!('totalByPage' in filters)) {
      filters.totalByPage = 5;
    }

    const { data: contas, pagination } = await getEnergiaContaPagined({
      ...filters,
      nrSeqEnergiaUnidadeConsumidora: registryKey,
    });

    if (gridViewContas && gridViewContas.current) {
      gridViewContas.current.setDataSource(contas, pagination);
    }
  };

  const onNew = (): void => {
    setData({});

    if (gridViewContas && gridViewContas.current) {
      gridViewContas.current.setDataSource([]);
    }

    if (gridViewConsumos && gridViewConsumos.current) {
      gridViewConsumos.current.setDataSource([]);
    }
  };

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      setData(await getEnergiaUnidadeConsumidora(registryKey));

      searchContas({});

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    load();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg } = await saveEnergiaUnidadeConsumidora(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
    }

    setData(status === ResponseStatus.Success ? {} : data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteEnergiaUnidadeConsumidora(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const onSearchCliente = async (e: string): Promise<Cliente[]> => {
    const {
      clientes,
      message: msg,
      status,
    }: {
      clientes: Cliente[];
      message: string;
      status: number;
    } = await getClienteAutoComplete({
      noPessoa: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return clientes;
  };

  const onSearchDistribuidora = async (
    e: string
  ): Promise<EnergiaDistribuidora[]> => {
    const {
      distribuidoras,
      message: msg,
      status,
    }: {
      distribuidoras: EnergiaDistribuidora[];
      message: string;
      status: number;
    } = await getEnergiaDistribuidoraAutoComplete({
      noEnergiaDistribuidora: e,
      cdEnergiaDistribuidora: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return distribuidoras;
  };

  const onSearchTipoTarifa = async (
    e: string
  ): Promise<EnergiaTipoTarifa[]> => {
    const {
      tipoTarifas,
      message: msg,
      status,
    }: {
      tipoTarifas: EnergiaTipoTarifa[];
      message: string;
      status: number;
    } = await getEnergiaTipoTarifaAutoComplete({
      noEnergiaTipoTarifa: e,
      cdEnergiaTipoTarifa: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return tipoTarifas;
  };

  const onSearchCidade = async (e: string): Promise<Cidade[]> => {
    const {
      cidades,
      message: msg,
      status,
    }: {
      cidades: Cidade[];
      message: string;
      status: number;
    } = await getCidadeEstadoAutoComplete({
      noCidade: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return cidades;
  };

  const onColumnSort = async (sortBy: string): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await searchContas(filters);
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await searchContas(filters);
  };

  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await searchContas(filters);
  };

  const onRowDoubleClick = (sourceRow: any[]): void => {
    const nrSeqEnergiaConta = sourceRow.find(
      (e) => e.key === 'nrSeqEnergiaConta'
    ).value;

    searchConsumos(nrSeqEnergiaConta);
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Unidade Consumidora'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={
        'nrSeqEnergiaUnidadeConsumidora' in data &&
        data.nrSeqEnergiaUnidadeConsumidora > 0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='UC'
            text={'nrUc' in data ? data?.nrUc : null}
            readOnly={'nrSeqEnergiaUnidadeConsumidora' in data}
            maxLength={60}
            required
            onChangedValue={(value: string) => {
              setData({ ...data, nrUc: value });
            }}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Titular'
            searchDataSource={onSearchCliente}
            required
            selectedItem={'cliente' in data ? data?.cliente : null}
            onSelectItem={(cliente: Cliente) => {
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Distribuidora'
            searchDataSource={onSearchDistribuidora}
            required
            selectedItem={'distribuidora' in data ? data?.distribuidora : null}
            onSelectItem={(distribuidora: EnergiaDistribuidora) => {
              setData({
                ...data,
                distribuidora,
                nrSeqEnergiaDistribuidora:
                  distribuidora.nrSeqEnergiaDistribuidora,
              });
            }}
            dataSourceTextProperty='noEnergiaDistribuidora'
          />
        </div>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='Cod. Cliente'
            text={'nrClienteUc' in data ? data?.nrClienteUc : null}
            readOnly={'nrSeqEnergiaUnidadeConsumidora' in data}
            maxLength={60}
            onChangedValue={(value: string) => {
              setData({ ...data, nrClienteUc: value });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <RadioButton
            label='Tipo de Forncimento'
            outline
            theme={Theme.Primary}
            size={BootstrapSizes.Small}
            buttons={tiposFornecimento}
            selectedButton={
              'tipoFornecimento' in data ? data.tipoFornecimento : null
            }
            onChange={(item: string) =>
              setData({
                ...data,
                tipoFornecimento: item,
              })
            }
            disabled={false}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Tipo de Tarifa'
            searchDataSource={onSearchTipoTarifa}
            required
            selectedItem={'tipoTarifa' in data ? data?.tipoTarifa : null}
            onSelectItem={(tipoTarifa: EnergiaTipoTarifa) => {
              setData({
                ...data,
                tipoTarifa,
                nrSeqEnergiaTipoTarifa: tipoTarifa.nrSeqEnergiaTipoTarifa,
              });
            }}
            dataSourceTextProperty='noEnergiaTipoTarifa'
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Tensão'
            text={'nrTensao' in data ? data?.nrTensao : null}
            maxLength={8}
            required
            mask={MaskTypes.Integer}
            onChangedValue={(value: number) => {
              if (Number.isNaN(value)) {
                onSetMessage(
                  ResponseStatus.Warning,
                  'São permitidos apenas numeros nesse campo.'
                );
              }
              setData({ ...data, nrTensao: value });
            }}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Tensão Min.'
            text={'nrTensaoMin' in data ? data?.nrTensaoMin : null}
            maxLength={8}
            required
            mask={MaskTypes.Integer}
            onChangedValue={(value: string) => {
              if (Number.isNaN(value)) {
                onSetMessage(
                  ResponseStatus.Warning,
                  'São permitidos apenas numeros nesse campo.'
                );
              }
              setData({ ...data, nrTensaoMin: value });
            }}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Tensão Max.'
            text={'nrTensaoMax' in data ? data?.nrTensaoMax : null}
            maxLength={8}
            required
            mask={MaskTypes.Integer}
            onChangedValue={(value: string) => {
              if (Number.isNaN(value)) {
                onSetMessage(
                  ResponseStatus.Warning,
                  'São permitidos apenas numeros nesse campo.'
                );
              }
              setData({ ...data, nrTensaoMax: value });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='Login'
            text={'noLogin' in data ? data?.noLogin : null}
            maxLength={60}
            onChangedValue={(value: string) =>
              setData({ ...data, noLogin: value })
            }
          />
        </div>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='Senha'
            text={'noSenha' in data ? data?.noSenha : null}
            maxLength={80}
            onChangedValue={(value: string) =>
              setData({ ...data, noSenha: value })
            }
          />
        </div>
      </div>

      {'nrSeqEnergiaUnidadeConsumidora' in data &&
      data.nrSeqEnergiaUnidadeConsumidora > 0 ? (
        <div className='row mb-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Faturas' theme={Theme.Primary} />
            <Panel.Body>
              <GridView
                ref={gridViewContas}
                // @ts-expect-error
                dataColumns={columnsContas}
                onColumnSort={onColumnSort}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                onRowDoubleClick={onRowDoubleClick}
                gridSizeList={[5, 10, 15]}
              />
            </Panel.Body>
          </Panel>
        </div>
      ) : null}

      <div className='row mb-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Endereço' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='CEP'
                  text={'endereco' in data ? data?.endereco?.cep : null}
                  maxLength={8}
                  required
                  onChangedValue={(value: string) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, cep: value }
                          : { cep: value },
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Bairro'
                  text={'endereco' in data ? data?.endereco?.bairro : null}
                  maxLength={60}
                  required
                  onChangedValue={(value: string) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, bairro: value }
                          : { bairro: value },
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Rua'
                  text={'endereco' in data ? data?.endereco?.noRua : null}
                  maxLength={80}
                  required
                  onChangedValue={(value: string) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, noRua: value }
                          : { noRua: value },
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Numero'
                  text={'endereco' in data ? data?.endereco?.numero : null}
                  maxLength={20}
                  required
                  onChangedValue={(value: number) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, numero: value }
                          : { numero: value },
                    });
                  }}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Complemento'
                  text={'endereco' in data ? data?.endereco?.complemento : null}
                  maxLength={40}
                  onChangedValue={(value: string) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, complemento: value }
                          : { complemento: value },
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Cidade'
                  searchDataSource={onSearchCidade}
                  required
                  selectedItem={
                    'endereco' in data ? data?.endereco?.cidade : null
                  }
                  onSelectItem={(cidade: Cidade) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? {
                              ...data.endereco,
                              nrSeqCidade: cidade.nrSeqCidade,
                              cidade,
                              estado: cidade.estado,
                            }
                          : {
                              nrSeqCidade: cidade.nrSeqCidade,
                              cidade,
                              estado: cidade.estado,
                            },
                    });
                  }}
                  dataSourceTextProperty='noCidade'
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Estado'
                  text={
                    'endereco' in data ? data?.endereco?.estado?.noEstado : null
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <ModalDetalhamentoConsumoFatura
        gridView={gridViewConsumos}
        loading={loadingModal}
        onClose={() => setShowModal(false)}
        show={showModal}
      />
    </CSDManutPage>
  );
}
