// #region IMPORTS
import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  RadioButton,
  DatePicker,
  GridView,
  Button,
  Panel,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  getFreteTerceiro,
  saveFreteTerceiro,
  printFreteTerceiroItem,
  printAutorizacaoAdto,
  printAutorizacaoSaldo,
  printAdiantamento,
  gerarCiot,
  encerrarCiot,
  retificarCiot,
  imprimirCiot,
  saveObservacaoFreteTerceiro,
  onCalculaTributos,
} from 'core/services/FRO/freteTerceiro';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import ModalGerarLancamentoValeMotorista from './modalLancarVale';
import ModalCancelaFrete from './modalCancelaFrete';
import ModalCancelaCIOT from './modalCancelaCiot';
import ModalValePedagio from './modalValePedagio';
import ModalAdicionarAdtosCheques from './modalAdicionarAdtosCheque.tsx';
import { getFornecedorAutoComplete } from '../../../../core/services/FIN/fornecedor';
// #endregion

export default function FreteTerceiroItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
  onOpenTransaction,
  findTransaction,
}) {
  // #region INICIALIZADORES, STATES, EFFECTS E REFS
  const gridViewAdiantamentos = useRef();
  const gridViewCteList = useRef();
  const gridViewPagamentosList = useRef();

  const [data, setData] = useState({
    flgCalcAbastecimento: 'N',
    vlrTerceiro: 0,
    vlrDescarga: 0,
    vlrAdiantamento: 0,
    vlrSaldo: 0,
    vlrAbatimento: 0,
    vlrAbastecimento: 0,
    vlrSestSenat: 0,
    vlrInssAutonomo: 0,
    vlrIrrf: 0,
    vlrInssEmpresa: 0,
    vlrValePedagio: 0,
  });
  const [valores, setValores] = useState({
    vlrTerceiro: 0,
    vlrDescarga: 0,
    vlrAdiantamento: 0,
    vlrSaldo: 0,
    vlrAbatimento: 0,
    vlrAbastecimento: 0,
    vlrValePedagio: 0,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({
    valePedagio: false,
    addAdtosCheques: false,
  });

  const formSubmit = useRef();
  const prevDataRef = useRef();

  useEffect(() => {
    prevDataRef.current = data;
  }, [data]);
  // #endregion

  // #region BÁSICAS
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const somaAdiantamentos = (adiantamentos) => {
    let vlrTotalAdiantamentos = 0.0;

    if (adiantamentos?.length > 0) {
      const soma = adiantamentos.reduce(
        (acc, atual) => acc + atual.vlrAdiantamento,
        0.0
      );

      vlrTotalAdiantamentos = soma.toFixed(2);
    }

    return vlrTotalAdiantamentos;
  };
  // #endregion

  // #region FUNÇÕES LOAD, NEW E SAVE
  const onNew = async () => {
    setLoading(true);

    setData({
      flgCalcAbastecimento: 'N',
      vlrTerceiro: 0,
      vlrDescarga: 0,
      vlrAdiantamento: 0,
      vlrSaldo: 0,
      vlrAbatimento: 0,
      vlrAbastecimento: 0,
      vlrSestSenat: 0,
      vlrInssAutonomo: 0,
      vlrIrrf: 0,
      vlrInssEmpresa: 0,
      vlrValePedagio: 0,
    });

    setValores({
      vlrTerceiro: 0,
      vlrDescarga: 0,
      vlrAdiantamento: 0,
      vlrSaldo: 0,
      vlrAbatimento: 0,
      vlrAbastecimento: 0,
      vlrValePedagio: 0,
    });

    if (gridViewAdiantamentos && gridViewAdiantamentos.current) {
      gridViewAdiantamentos.current.setDataSource([]);
    }

    if (gridViewCteList && gridViewCteList.current) {
      gridViewCteList.current.setDataSource([]);
    }

    if (gridViewPagamentosList && gridViewPagamentosList.current) {
      gridViewPagamentosList.current.setDataSource([]);
    }

    setLoading(false);
  };

  const getPagamentoData = (tituloPagar) => {
    if (tituloPagar.pagamentos.length > 0) {
      tituloPagar.dtPagamento = tituloPagar.pagamentos[0].dtPagamento;
    }
    return null;
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const res = await getFreteTerceiro(registryKey);

      if (Object.keys(res).length > 0) {
        res.faturaLista.map((elem) => getPagamentoData(elem.tituloPagar));

        const obj = res;
        obj.noStatusCiot = '';

        if (obj.noObsCiot.length === 0) {
          obj.noStatusCiot = 'Nunca Emitido';
        } else {
          obj.noStatusCiot = 'Emitido';
        }

        if (obj.noObsCiot.includes('Cancelado')) {
          obj.noStatusCiot += ' e Cancelado';
        }
        if (obj.noObsCiot.includes('Encerrado')) {
          obj.noStatusCiot += ' e Encerrado';
        }

        setData({
          ...res,
          dtCadastro: res.dtCadastro,
          dtEmissao: res.dtEmissao,
          dtAlteracao: res.dtAlteracao,
          flgCalcAbastecimento: 'N',
          flgStatus: res.flgCancelado === true ? 'S' : 'N',
          vlrTotalAdiantamentos: somaAdiantamentos(res.acertoMotoristaLista),
        });

        if (gridViewAdiantamentos && gridViewAdiantamentos.current) {
          gridViewAdiantamentos.current.setDataSource(res.acertoMotoristaLista);
        }

        if (gridViewCteList && gridViewCteList.current) {
          gridViewCteList.current.setDataSource(res.coletaEmbarqueLista);
        }

        if (gridViewPagamentosList && gridViewPagamentosList.current) {
          gridViewPagamentosList.current.setDataSource(res.faturaLista);
        }
      } else {
        onSetMessage(
          ResponseStatus.Error,
          'Não foi possível carregar o Recibo de Frete. Contate a equipe de suporte.'
        );
        onNew();
      }

      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    if (data.flgCalcAbastecimento === 'S') data.flgCalculaAbastecimento = true;
    else data.flgCalculaAbastecimento = false;

    const { status, message: msg } = await saveFreteTerceiro(data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onSaveObs = async () => {
    setLoading(true);

    const { status, message: msg } = await saveObservacaoFreteTerceiro(data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onPrint = async (obj) => {
    setLoading(true);

    if ('nrSeqFreteTerceiro' in obj) {
      const { value } = await printFreteTerceiroItem(obj);

      onOpenReport(transaction.noTransacao, value);
    } else {
      const { value } = await printFreteTerceiroItem({
        nrSeqFreteTerceiro: data.nrSeqFreteTerceiro,
      });

      onOpenReport(transaction.noTransacao, value);
    }

    setLoading(false);
  };

  const onOpenDetalharCTEs = async () => {
    setLoading(true);

    const transactionOpen = findTransaction(TransacationCodes.Viagem);

    const { nrSeqViagem } = data.coletaEmbarqueLista[0];

    if (transactionOpen) {
      transactionOpen.registryKey = nrSeqViagem;
      onOpenTransaction(transactionOpen, true);
    } else {
      onSetMessage(ResponseStatus.Error, 'Não foi possível abrir a viagem.');
    }

    setLoading(false);
  };
  // #endregion

  // #region FUNÇÕES BOTÕES
  const onCancelarFrete = () => {
    if (!data?.flgCancelado) {
      setModalShow({ ...modalShow, cancelaFrete: true });
    } else {
      setMessage({
        message: 'Recibo de Frete já foi cancelado.',
        theme: Theme.Warning,
      });
    }
  };

  const onOpenValeMotorista = () => {
    if (typeof data.vlrTerceiro !== 'undefined' && data.vlrTerceiro > 0) {
      setModalShow({ ...modalShow, gerarLancamentoVale: true });
    } else {
      setMessage({
        message: 'Não há valor de repasse ou é inválido.',
        theme: Theme.Warning,
      });
    }
  };

  const onGerarCiot = async (tipoIntegracao) => {
    setLoading(true);

    const { status, message: msg, value } = await gerarCiot({
      ...data,
      noTipoIntegracao: tipoIntegracao,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);

    return value;
  };

  const onCancelarCiot = () => {
    if (!data?.flgCancelado) {
      setModalShow({ ...modalShow, cancelaCIOT: true });
    } else {
      setMessage({
        message: 'Recibo de Frete já foi cancelado.',
        theme: Theme.Warning,
      });
    }
  };

  const onEncerrarCiot = async () => {
    setLoading(true);

    const { status, message: msg, value } = await encerrarCiot(data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);

    return value;
  };

  const onRetificarCiot = async () => {
    setLoading(true);

    const { status, message: msg, value } = await retificarCiot(data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);

    return value;
  };

  const onGerarPdfCiot = async () => {
    setLoading(true);

    const { status, message: msg, value } = await imprimirCiot(
      data.nrSeqFreteTerceiro
    );

    if (status === ResponseStatus.Error) {
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });
    } else {
      onOpenReport(transaction.noTransacao, value);
    }

    setLoading(false);
  };

  const onValePedagio = () => {
    if (data.vlrAdiantamento > 0) {
      if (!data?.flgCancelado) {
        setModalShow({ ...modalShow, valePedagio: true });
      } else {
        setMessage({
          message: 'Recibo de Frete já foi cancelado.',
          theme: Theme.Warning,
        });
      }
    } else {
      onSetMessage(
        ResponseStatus.Warning,
        'O valor de Adiantamento não pode ser 0.'
      );
    }
  };
  // #endregion

  // #region DETALHES
  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgBuscaSimples: true,
    });

    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onImprimeAutorizacaoAdto = async () => {
    setLoading(true);

    if (data.flgCalcAbastecimento === 'S') data.flgCalculaAbastecimento = true;
    else data.flgCalculaAbastecimento = false;

    const frete = { nrSeqFreteTerceiro: data.nrSeqFreteTerceiro };
    const { value } = await printAutorizacaoAdto(frete);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onImprimeAutorizacaoSaldo = async () => {
    setLoading(true);

    if (data.flgCalcAbastecimento === 'S') data.flgCalculaAbastecimento = true;
    else data.flgCalculaAbastecimento = false;

    const frete = { nrSeqFreteTerceiro: data.nrSeqFreteTerceiro };
    const { value } = await printAutorizacaoSaldo(frete);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const calcAbastecimentoStatus = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const onClickImprimir = async (e) => {
    setLoading(true);

    const { value } = await printAdiantamento(e);
    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onClickRemoveAdto = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    if (gridViewAdiantamentos?.current)
      gridViewAdiantamentos.current.setDataSource(datasource);
  };

  const columnsAdiantamentos = [
    { key: 'dtAdiantamento', title: 'Data', format: GridView.DataTypes.Date },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma de Pagamento',
    },
    {
      key: 'vlrAdiantamento',
      title: 'Valor Adiantamento',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickImprimir(e, datasource),
      theme: Theme.Warning,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveAdto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsCTE = [
    { key: 'cdColetaEmbarque', title: 'Nr CTE' },
    {
      key: 'dtEmissao',
      title: 'Data de Saída',
      format: GridView.DataTypes.Date,
    },
    { key: 'remetente.noPessoa', title: 'Remetente' },
    {
      key: 'destinatario.noPessoa',
      title: 'Destinatário',
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Money,
    },
  ];

  const columnsPagamentos = [
    {
      key: 'dtVencimento',
      title: 'Data Vencimento Saída',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Título',
      format: GridView.DataTypes.Money,
    },
    { key: 'tituloPagar.cdTituloPagar', title: 'Compromisso' },
    {
      key: 'tituloPagar.dtPagamento',
      title: 'Pagamentos',
      format: GridView.DataTypes.Date,
    },
  ];

  const calculaTributos = async () => {
    setLoading(true);

    const objTributos = {
      ...data,
      ...valores,
      vlrTerceiro: valores.vlrTerceiro,
      vlrDescarga: valores.vlrDescarga,
      vlrAbatimento: valores.vlrAbatimento,
      flgCalculaAbastecimento: data?.flgCalcAbastecimento === 'S',
    };

    const { status, message: msg, value } = await onCalculaTributos(
      objTributos
    );
    setLoading(false);

    if (status === ResponseStatus.Error) return onSetMessage(status, msg);

    return setData({ ...data, ...value });
  };

  const calculaValoresDetalhes = () => {
    if (data.vlrTerceiro > data.vlrFrete) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Valor a pagar terceiro é maior que o valor do frete.'
      );
    }

    if (valores.vlrAdiantamento > data.vlrTerceiro) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Valor adiantamento não pode ser maior que valor do repasse terceiro.'
      );
    }

    const vlrSaldo = data.vlrSaldo - valores.vlrAdiantamento;
    return setData({
      ...data,
      vlrSaldo,
      vlrAdiantamento: valores.vlrAdiantamento,
    });
  };

  const onSearchFornecedor = async (e) => {
    if (e.length < 3) {
      onSetMessage(ResponseStatus.Warning, 'Digite ao menos 3 caracteres.');

      return [];
    }

    const { data: fornecedores } = await getFornecedorAutoComplete({
      noPessoa: e,
    });

    return fornecedores;
  };
  // #endregion

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      title='Recibo de Frete 3.0 - Manutenção'
      isActive={isActive}
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      // onSave={save}
      onPrint={Number.isInteger(registryKey) ? onPrint : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {/* Botões */}
      <>
        {/* Salvar */}
        {!data?.flgCancelado &&
          (data?.noStatusCiot === 'Nunca Emitido' ||
            data?.noStatusCiot === 'Emitido e Cancelado') && (
            <ToolbarButtons>
              <ToolbarButtons.Button text='Salvar Frete' onClick={save} />
            </ToolbarButtons>
          )}
        {/* Cancelar */}
        {!data?.flgCancelado &&
          (data?.noStatusCiot === 'Nunca Emitido' ||
            data?.noStatusCiot === 'Emitido e Cancelado') && (
            <ToolbarButtons>
              <ToolbarButtons.Button
                text='Cancelar Frete'
                onClick={() => onCancelarFrete()}
              />
            </ToolbarButtons>
          )}
        {/* Botão de Lançar Vale */}
        {!data?.flgCancelado &&
          (data?.noStatusCiot === 'Nunca Emitido' ||
            data?.noStatusCiot === 'Emitido e Cancelado') && (
            <ToolbarButtons>
              <ToolbarButtons.Button
                text='Lançar Vale'
                onClick={() => onOpenValeMotorista()}
              />
            </ToolbarButtons>
          )}
        {/* Botão de Gerar CIOT */}
        {!data?.flgCancelado &&
          (data?.noStatusCiot === 'Nunca Emitido' ||
            data?.noStatusCiot === 'Emitido e Cancelado') && (
            <ToolbarButtons>
              <ToolbarButtons.ButtonList
                text='Gerar CIOT'
                icon='caret-down'
                items={[
                  {
                    text: 'eFrete',
                    onClick: () => onGerarCiot('eFrete'),
                  },
                  {
                    text: 'TruckPad',
                    onClick: () => onGerarCiot('TruckPad'),
                  },
                  {
                    text: 'NddCargo',
                    onClick: () => onGerarCiot('NddCargo'),
                  },
                ]}
              />
            </ToolbarButtons>
          )}
        {/* Botão de Encerrar CIOT */}
        {!data?.flgCancelado && data?.noStatusCiot === 'Emitido' && (
          <ToolbarButtons>
            <ToolbarButtons.Button
              text='Encerrar CIOT'
              onClick={() => onEncerrarCiot()}
            />
          </ToolbarButtons>
        )}
        {/* Botão de Cancelar CIOT */}
        {!data?.flgCancelado && data?.noStatusCiot === 'Emitido' && (
          <ToolbarButtons>
            <ToolbarButtons.Button
              text='Cancelar CIOT'
              onClick={() => onCancelarCiot()}
            />
          </ToolbarButtons>
        )}
        {/* Botão de Gerar Pdf CIOT */}
        {!data?.flgCancelado && data?.noStatusCiot !== 'Nunca Emitido' && (
          <ToolbarButtons>
            <ToolbarButtons.Button
              text='Imprimir Pdf CIOT'
              onClick={() => onGerarPdfCiot()}
            />
          </ToolbarButtons>
        )}
        {/* Botão de Retificar CIOT */}
        {!data?.flgCancelado && data?.noStatusCiot === 'Emitido' && (
          <ToolbarButtons>
            <ToolbarButtons.Button
              text='Retificar CIOT'
              onClick={() => onRetificarCiot()}
            />
          </ToolbarButtons>
        )}
        {/* Botão Vale Pedágio */}
        {!data?.flgCancelado &&
          (data?.noStatusCiot === 'Nunca Emitido' ||
            data?.noStatusCiot === 'Emitido e Cancelado') && (
            <ToolbarButtons>
              <ToolbarButtons.Button
                text='Vale Pedágio - TruckPad'
                onClick={() => onValePedagio()}
              />
            </ToolbarButtons>
          )}
      </>

      {/* Detalhes CIOT */}
      <div className='row'>
        <p>{data.noObsCiot}</p>
      </div>

      {/* Painel Dados Iniciais */}
      <>
        {/* Linha 1 */}
        <div className='row mb-2'>
          {/* Unidade Origem */}
          <div className='col'>
            <Textbox
              readOnly
              label='Unidade Origem'
              text={data?.empresa?.noPessoa}
            />
          </div>

          {/* Data de Cadastro */}
          <div className='col-2'>
            <DatePicker
              label='Data de Cadastro'
              readOnly
              text={data?.dtCadastro}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtCadastro) => {
                setData({ ...data, dtCadastro });
              }}
            />
          </div>

          {/* Data de Emissão */}
          <div className='col-2'>
            <DatePicker
              label='Data de Emissão'
              readOnly
              text={data?.dtEmissao}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtEmissao) => {
                setData({ ...data, dtEmissao });
              }}
            />
          </div>

          {/* Ordem Frete */}
          <div className='col-2'>
            <Textbox readOnly label='Ordem Frete' text={data?.nroOrdem} />
          </div>
        </div>

        {/* Linha 2 */}
        <div className='row mb-2'>
          {/* Proprietário */}
          <div className='col'>
            <Textbox
              readOnly
              label='Transportadora'
              text={data?.proprietario?.noPessoa}
            />
          </div>

          {/* Motorista */}
          <div className='col'>
            <Textbox
              readOnly
              label='Motorista'
              text={data?.motorista?.noPessoa}
            />
          </div>

          {/* Veículo */}
          <div className='col-2'>
            <Textbox readOnly label='Veículo' text={data?.veiculo?.placa} />
          </div>
        </div>

        {/* Linha 3 */}
        <div className='row mb-2'>
          {/* Cidade Origem */}
          <div className='col'>
            <Textbox
              readOnly
              label='Cidade Origem'
              text={data?.cidadeOrigem?.noCidade}
            />
          </div>

          {/* Estado Origem */}
          <div className='col-2'>
            <Textbox
              readOnly
              label='Estado Origem'
              text={data?.cidadeOrigem?.estado.noEstado}
            />
          </div>

          {/* Peso */}
          <div className='col-2'>
            <Textbox
              readOnly
              label='Peso'
              mask={MaskTypes.Decimal}
              text={data?.peso}
            />
          </div>

          {/* Valor Conhecimento */}
          <div className='col-2'>
            <Textbox
              readOnly={data?.flgStatus === 'S' || !data?.flgCampoHabilitado}
              label='Valor Conhecimento'
              mask={MaskTypes.Decimal}
              text={data?.vlrFrete}
            />
          </div>
        </div>

        {/* Linha 4 */}
        <div className='row mb-4'>
          {/* Cidade Destino */}
          <div className='col'>
            <Textbox
              readOnly
              label='Cidade Destino'
              text={data?.cidadeDestino?.noCidade}
            />
          </div>

          {/* Estado Destino */}
          <div className='col-2'>
            <Textbox
              readOnly
              label='Estado Destino'
              text={data?.cidadeDestino?.estado.noEstado}
            />
          </div>

          {/* Data Prevista */}
          <div className='col-3'>
            <DatePicker
              label='Data Prevista'
              readOnly={data?.flgStatus === 'S'}
              text={data?.dtPrevistaEntrega}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtPrevistaEntrega) => {
                setData({ ...data, dtPrevistaEntrega });
              }}
            />
          </div>

          {/* Status Frete */}
          {!data?.flgCancelado ? (
            <div className='col-2 mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Status Frete - Ativo'
                readOnly
              />
            </div>
          ) : (
            <div className='col-2 mt-3'>
              <Button
                theme={Theme.Danger}
                template={Button.Templates.Button}
                text='Status Frete - Cancelado'
                readOnly
              />
            </div>
          )}
        </div>
      </>
      {/* Painel Detalhes */}
      <Panel className='mb-2'>
        <Panel.Header title='Detalhes' theme={Theme.Primary} />
        <Panel.Body>
          {/* Linha 1 */}
          <div className='row mb-2'>
            {/* Produto */}
            <div className='col'>
              <Textbox
                readOnly
                label='Produto'
                text={data?.freteMercadoria?.noFreteMercadoria}
              />
            </div>

            {/* Total Vale */}
            <div className='col-2'>
              <Textbox
                readOnly
                label='Total Vale'
                mask={MaskTypes.Decimal}
                text={data?.totalVale}
              />
            </div>
          </div>

          {/* Linha 2 */}
          <div className='row mb-2'>
            {/* *Repasse Terceiro */}
            <div className='col-3'>
              <Textbox
                label='*Repasse Terceiro'
                readOnly={data?.flgStatus === 'S' || !data?.flgCampoHabilitado}
                mask={MaskTypes.Decimal}
                text={data?.vlrTerceiro}
                onBlur={calculaTributos}
                onChangedValue={(vlrTerceiro) => {
                  setValores({
                    ...valores,
                    vlrTerceiro,
                  });
                }}
              />
            </div>

            {/* *Descarga */}
            <div className='col-3'>
              <Textbox
                label='*Descarga'
                readOnly={data?.flgStatus === 'S' || !data?.flgCampoHabilitado}
                mask={MaskTypes.Decimal}
                text={data?.vlrDescarga}
                onBlur={calculaTributos}
                onChangedValue={(vlrDescarga) => {
                  setValores({
                    ...valores,
                    vlrDescarga,
                  });
                }}
              />
            </div>
          </div>

          {/* Linha 3 - Adiantamento */}
          <div className='row mb-2'>
            {/* Valor Adto */}
            <div className='col-2'>
              <Textbox
                label='Valor Adto'
                readOnly={
                  data?.flgStatus === 'S' ||
                  !data?.flgCampoHabilitado ||
                  valores?.vlrTerceiro <= 0
                }
                mask={MaskTypes.Decimal}
                text={data?.vlrAdiantamento}
                onBlur={calculaValoresDetalhes}
                onChangedValue={(vlrAdiantamento) => {
                  setValores({
                    ...valores,
                    vlrAdiantamento,
                  });
                }}
              />
            </div>

            {/* Data Pgto Adto */}
            <div className='col-2'>
              <DatePicker
                label='Data Pgto Adto'
                readOnly={data?.flgStatus === 'S' || !data?.flgCampoHabilitado}
                text={data?.dtPagamentoAdto}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPagamentoAdto) => {
                  setData({ ...data, dtPagamentoAdto });
                }}
              />
            </div>

            {/* Data Recebimento Adto */}
            <div className='col-2'>
              <DatePicker
                label='Data Recebimento Adto'
                readOnly={data?.flgStatus === 'S'}
                text={data?.dtRecebimentoAdto}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtRecebimentoAdto) => {
                  setData({ ...data, dtRecebimentoAdto });
                }}
              />
            </div>

            {/* *Forma Pgto Adto */}
            <div className='col-2'>
              <Autocomplete
                label='*Forma Pgto Adto'
                readOnly={data?.flgStatus === 'S' || !data?.flgCampoHabilitado}
                searchDataSource={onSearchFormaPagamento}
                selectedItem={data?.formaPagamentoAdto}
                onSelectItem={(formaPagamentoAdto) => {
                  setData({
                    ...data,
                    formaPagamentoAdto,
                    nrSeqFormaPagamentoAdto:
                      formaPagamentoAdto.nrSeqFormaPagamento,
                  });
                }}
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>

            {/* DatePicker se for cheque */}
            {data.formaPagamentoAdto?.flgGrupo === 'C' && (
              <>
                <div className='col-2'>
                  <DatePicker
                    label='Data Cheque'
                    readOnly={data?.flgStatus === 'S'}
                    text={data?.dtCheque}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtCheque) => {
                      setData({ ...data, dtCheque });
                    }}
                  />
                </div>

                {(data?.flgStatus === 'S' || !data?.flgCampoHabilitado) && (
                  <div className='col-2 d-flex align-items-end'>
                    <Button
                      text='Adicionar Cheques'
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      onClick={() =>
                        setModalShow({
                          ...modalShow,
                          addAdtosCheques: true,
                        })
                      }
                    />
                  </div>
                )}
              </>
            )}

            {data.formaPagamentoAdto?.flgGrupo === 'C' &&
              !data.formaPagamentoAdto?.flgControlaCheque && (
                <div className='col'>
                  <Textbox
                    label='Nr. Cheque Adto'
                    mask={MaskTypes.Integer}
                    text={data?.nrCheque}
                    onChangedValue={(nrCheque) => {
                      setData({
                        ...data,
                        nrCheque,
                      });
                    }}
                  />
                </div>
              )}

            {/* Imprime Autorização Adiantamento */}
            {data.formaPagamentoAdto?.noFormaPagamento ===
              'Posto Combustível' && (
              <div className='col-2 btn-group mr-2'>
                <Button
                  text='Autorização Adiantamento'
                  className='mb-3 mt-4'
                  icon='print'
                  size={BootstrapSizes.Large}
                  theme={Theme.Success}
                  template={Button.Templates.Default}
                  onClick={onImprimeAutorizacaoAdto}
                  outline
                />
              </div>
            )}
          </div>

          {/* Fornecedor Adiantamento */}
          {data?.formaPagamentoAdto?.flgVinculaFornecedor && (
            <div className='row col-4 mb-2'>
              <Autocomplete
                label='Fornecedor Adiantamento'
                searchDataSource={onSearchFornecedor}
                selectedItem={data?.fornecedorAdto}
                onSelectItem={(fornecedorAdto) => {
                  setData({
                    ...data,
                    fornecedorAdto,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          )}

          {/* Linha 4 - Saldo */}
          <div className='row mb-2'>
            {/* Valor Saldo */}
            <div className='col-2'>
              <Textbox
                label='Valor Saldo'
                readOnly
                mask={MaskTypes.Decimal}
                text={data?.vlrSaldo}
              />
            </div>

            {/* Data Pgto Saldo */}
            <div className='col-2'>
              <DatePicker
                label='Data Pgto Saldo'
                readOnly={data?.flgStatus === 'S' || !data?.flgCampoHabilitado}
                text={data?.dtPagamentoSaldo}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPagamentoSaldo) => {
                  setData({ ...data, dtPagamentoSaldo });
                }}
              />
            </div>

            {/* Data Recebimento Saldo */}
            <div className='col-2'>
              <DatePicker
                label='Data Recebimento Saldo'
                readOnly={data?.flgStatus === 'S'}
                text={data?.dtRecebimentoSaldo}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtRecebimentoSaldo) => {
                  setData({ ...data, dtRecebimentoSaldo });
                }}
              />
            </div>

            {/* *Forma Pgto Saldo */}
            <div className='col-2'>
              <Autocomplete
                label='*Forma Pgto Saldo'
                readOnly={data?.flgStatus === 'S'}
                searchDataSource={onSearchFormaPagamento}
                selectedItem={data.formaPagamentoSaldo}
                onSelectItem={(formaPagamentoSaldo) => {
                  setData({
                    ...data,
                    formaPagamentoSaldo,
                    nrSeqFormaPagamentoSaldo:
                      formaPagamentoSaldo.nrSeqFormaPagamento,
                  });
                }}
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>

            {/* DatePicker se for cheque */}
            {data.formaPagamentoSaldo?.flgGrupo === 'C' && (
              <div className='col-2'>
                <DatePicker
                  label='Data Cheque'
                  readOnly={data?.flgStatus === 'S'}
                  text={data?.dtChequeSaldo}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtChequeSaldo) => {
                    setData({ ...data, dtChequeSaldo });
                  }}
                />
              </div>
            )}

            {data.formaPagamentoSaldo?.flgGrupo === 'C' &&
              !data.formaPagamentoSaldo?.flgControlaCheque && (
                <div className='col-2'>
                  <Textbox
                    label='Nr. Cheque Saldo'
                    mask={MaskTypes.Integer}
                    text={data?.nrChequeSaldo}
                    onChangedValue={(nrChequeSaldo) => {
                      setData({
                        ...data,
                        nrChequeSaldo,
                      });
                    }}
                  />
                </div>
              )}

            {/* Imprime Autorização Saldo */}
            {data.formaPagamentoSaldo?.noFormaPagamento ===
              'Posto Combustível' && (
              <div className='col-2 btn-group'>
                <Button
                  text='Autorização Saldo'
                  className='mb-3 mt-4'
                  icon='print'
                  size={BootstrapSizes.Small}
                  theme={Theme.Success}
                  template={Button.Templates.Default}
                  onClick={onImprimeAutorizacaoSaldo}
                  outline
                />
              </div>
            )}
          </div>

          {/* Fornecedor Saldo */}
          {data?.formaPagamentoSaldo?.flgVinculaFornecedor && (
            <div className='row col-4 mb-2'>
              <Autocomplete
                label='Fornecedor Adiantamento'
                searchDataSource={onSearchFornecedor}
                selectedItem={data?.fornecedorSaldo}
                onSelectItem={(fornecedorSaldo) => {
                  setData({
                    ...data,
                    fornecedorSaldo,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          )}

          {/* Linha 5 */}
          <div className='row mb-2'>
            {/* Valor Abatimento */}
            <div className='col-2'>
              <Textbox
                readOnly={data?.flgStatus === 'S' || !data?.flgCampoHabilitado}
                label='Valor Abatimento'
                mask={MaskTypes.Decimal}
                text={data?.vlrAbatimento}
                onBlur={calculaTributos}
                onChangedValue={(vlrAbatimento) => {
                  setValores({
                    ...valores,
                    vlrAbatimento,
                  });
                }}
              />
            </div>

            {/* Motivo Abatimento */}
            <div className='col'>
              <Textbox
                readOnly={data?.flgStatus === 'S'}
                label='Motivo Abatimento'
                text={data?.noAbatimento}
                onChange={(noAbatimento) => {
                  setData({
                    ...data,
                    noAbatimento,
                  });
                }}
              />
            </div>
          </div>

          {/* Linha 6 */}
          <div className='row mb-2'>
            {/* Sest/Senat */}
            <div className='col'>
              <Textbox
                readOnly
                label='Sest/Senat'
                mask={MaskTypes.Decimal}
                text={data?.vlrSestSenat}
              />
            </div>

            {/* INSS Autonomo */}
            <div className='col'>
              <Textbox
                readOnly
                label='INSS Autonomo'
                mask={MaskTypes.Decimal}
                text={data?.vlrInssAutonomo}
              />
            </div>

            {/* INSS Empresa */}
            <div className='col'>
              <Textbox
                readOnly
                label='INSS Empresa'
                mask={MaskTypes.Decimal}
                text={data?.vlrInssEmpresa}
              />
            </div>

            {/* Valor IRRF */}
            <div className='col'>
              <Textbox
                readOnly
                label='Valor IRRF'
                mask={MaskTypes.Decimal}
                text={data?.vlrIrrf}
              />
            </div>
          </div>

          {/* Linha 7 */}
          <div className='row mb-2'>
            {/* Calcula Abastecimento */}
            <div className='col-2 d-flex flex-column align-items-start flex-wrap-nowrap'>
              <RadioButton
                label='Calcula Abastecimento'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={data?.flgCalcAbastecimento}
                buttons={calcAbastecimentoStatus}
                onChange={(flgCalcAbastecimento) => {
                  setData({ ...data, flgCalcAbastecimento });
                }}
              />
            </div>

            {/* Valor Abastecimento */}
            <div className='col-2'>
              <Textbox
                readOnly={data?.flgStatus === 'S'}
                label='Valor Abastecimento'
                mask={MaskTypes.Decimal}
                text={data?.vlrAbastecimento}
                onBlur={() => {
                  if (data.flgCalcAbastecimento === 'S') {
                    calculaTributos();
                  }
                }}
                onChangedValue={(vlrAbastecimento) => {
                  setData({ ...data, vlrAbastecimento });
                  setValores({
                    ...valores,
                    vlrAbastecimento,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      {/* Grid Adiantamentos */}
      <Panel className='mb-3'>
        <Panel.Header title='Adiantamentos' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row'>
            <div className='col-12'>
              <GridView
                ref={gridViewAdiantamentos}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsAdiantamentos}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
          {/* Total Adiantamentos */}
          <div className='row bg-inactive py-1'>
            <div className='col'>
              <p>
                <b>Total Adiantamentos: R$ </b>
                {data.vlrTotalAdiantamentos}
              </p>
            </div>
          </div>
        </Panel.Body>
      </Panel>
      {/* CTEs relacionados */}
      <Panel className='mb-3'>
        <Panel.Header
          title={`CTEs relacionados: ${data.cteConcat}`}
          theme={Theme.Primary}
        />
        <Panel.Body>
          {/* Linha 1 */}
          <div className='row mb-3'>
            {/* Observações */}
            <div className='col'>
              <Textbox
                label='Observações'
                text={data?.obs}
                onChangedValue={(obs) => setData({ ...data, obs })}
              />
            </div>

            {/* Salvar Observação */}
            <div className='col-2 mt-3'>
              <Button
                theme={Theme.Primary}
                outline
                template={Button.Templates.Button}
                text='Salvar Observação'
                onClick={onSaveObs}
              />
            </div>

            {/* Detalhar CTEs */}
            <div className='col-2 mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Detalhar CTEs'
                onClick={onOpenDetalharCTEs}
              />
            </div>
          </div>

          {/* Linha 2 */}
          <div className='row bg-inactive py-1'>
            {/* Cadastrado por */}
            <div className='col'>
              <p>
                <b>Cadastrado por:</b> {data.usuarioCad?.noLogin}
              </p>
            </div>

            {/* Cadastrado em */}
            <div className='col'>
              <p>
                <b>Cadastrado em:</b> {data?.dtCadastro}
              </p>
            </div>

            {/* Ultima Alteração por */}
            <div className='col'>
              <p>
                <b>Ultima Alteração por:</b> {data.usuarioAlt?.noLogin}
              </p>
            </div>

            {/* Ultima Alteração em */}
            <div className='col'>
              <p>
                <b>Ultima Alteração em:</b> {data?.dtAlteracao}
              </p>
            </div>
          </div>

          {/* Linha 3 */}
          <div className='row'>
            {/* Grid CTEs */}
            <div className='col-12'>
              <GridView
                ref={gridViewCteList}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsCTE}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      {/* Grid Pagamentos */}
      <div className='row'>
        <div className='col-12'>
          <GridView
            ref={gridViewPagamentosList}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsPagamentos}
            offlineData
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <ModalGerarLancamentoValeMotorista
        show={modalShow.gerarLancamentoVale}
        onClose={(res) => {
          if (res?.status === 'Inserir') {
            const acertos = [...data.acertoMotoristaLista];
            acertos.push(res);

            setData({ ...data, acertoMotoristaLista: acertos });

            if (gridViewAdiantamentos?.current)
              gridViewAdiantamentos.current.setDataSource(acertos);
          }

          setModalShow({ ...modalShow, gerarLancamentoVale: false });
        }}
        data={data}
      />
      <ModalCancelaFrete
        show={modalShow.cancelaFrete}
        onClose={(res) => {
          setModalShow({ ...modalShow, cancelaFrete: false });

          if (res?.status === ResponseStatus.Success) {
            onSetMessage(ResponseStatus.Success, res.message);
            onNew();
          }
        }}
        data={data}
        setData={setData}
      />
      <ModalCancelaCIOT
        show={modalShow.cancelaCIOT}
        onClose={(res) => {
          setModalShow({ ...modalShow, cancelaCIOT: false });

          if (res?.status === ResponseStatus.Success) {
            onSetMessage(ResponseStatus.Success, res.message);
            onNew();
          }
        }}
        data={data}
        setData={setData}
      />
      <ModalValePedagio
        show={modalShow.valePedagio}
        onClose={() => {
          setModalShow({ ...modalShow, valePedagio: false });
        }}
        data={data}
        setData={setData}
      />
      <ModalAdicionarAdtosCheques
        show={modalShow.addAdtosCheques}
        onClose={() => {
          setModalShow({ ...modalShow, addAdtosCheques: false });
        }}
        data={data}
        setData={setData}
      />
    </CSDManutPage>
  );
}
