import React, { useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  getCustoFixoPagined,
  printCustoFixo,
  deleteAllCustoFixo,
  updateReferenceSalaryDriversAndEmployees,
} from 'core/services/FIN/custoFixo';
import {
  Autocomplete,
  CSDSelPage,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import { getTipoDespesaList } from 'core/services/FIN';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getFornecedorList } from 'core/services/FIN/fornecedor';
import ModalGerarLancamentos from './ModalGerarLancamentos';
import ModalAlteracaoDeDia from './ModalAlteracaoDeDia';

const columns = [
  { key: 'nrSeqCustoFixo', type: GridView.ColumnTypes.Checkbox },
  { key: 'nrTit', title: 'Nr. Tit' },
  { key: 'fornecedor.noPessoa', title: 'Fornecedor', sortKey: 'noFornecedor' },
  {
    key: 'tipoDespesa.noTipoDespesa',
    title: 'Tipo',
    sortKey: 'noTipoDespesa',
  },
  {
    key: 'noPlanoConta',
    title: 'Plano Conta',
  },
  { key: 'vlrCusto', title: 'Valor' },
  { key: 'diaVencimento', title: 'Dia' },
  { key: 'flgAtivo', title: 'Ativo' },
];

export default function CustoFixo({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [modalShow, setModalShow] = useState({});
  const [data, setData] = useState({});
  const [filtros, setFiltros] = useState({ tipoAtivos: 'T' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data: data2, pagination } = await getCustoFixoPagined(
      params ?? filtros
    );

    if (data2 !== null || data2 !== undefined) {
      data2.forEach((item) => {
        item.noPlanoConta = item.planoDeConta.planoDeContaCre.cdCaminhoCompleto;
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(data2, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'CustoFixo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqCustoFixo = sourceRow.find((e) => e.key === 'nrSeqCustoFixo');
    onOpenMaintenance(nrSeqCustoFixo.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printCustoFixo(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllCustoFixo(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const tipoAtivo = [
    {
      text: 'Ativo',
      value: 'A',
    },
    {
      text: 'Inativo',
      value: 'I',
    },
    {
      text: 'Todos',
      value: 'T',
    },
  ];

  const onSearchFornecedor = async (e) => {
    const { pessoaFors } = await getFornecedorList({
      noFornecedor: e,
    });

    return pessoaFors;
  };
  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const onSearchTipoDespesa = async (e) => {
    const { data: tipoDespesa } = await getTipoDespesaList({
      noTipoDespesa: e,
    });

    return tipoDespesa;
  };

  const onOpenLancamento = async () => {
    setModalShow({
      ...modalShow,
      gerarLancamento: true,
    });
  };
  const download = (text) => {
    const element = document.createElement('a');

    const link = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;

    element.setAttribute('href', link);

    element.setAttribute('download', 'resumo.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const onUpdateReferenceSalaryDriversAndEmployees = async () => {
    setLoading(true);
    const {
      message: msg,
      status,
      value,
    } = await updateReferenceSalaryDriversAndEmployees();

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    download(value);
    setLoading(false);
  };

  const onOpenAlteracaoDia = async () => {
    const itensSelecionados = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (itensSelecionados.length === 0) {
      setMessage({
        message: 'Selecione um item para alterar a data.',
        theme: Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      alteracoesDia: itensSelecionados,
      alteracaoDia: true,
    });
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Custo Fixo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gerar lançamentos'
          icon='envelope'
          onClick={onOpenLancamento}
        />
        <ToolbarButtons.Button
          text='Alterar Dia do Lançamento'
          icon='envelope'
          onClick={onOpenAlteracaoDia}
        />
        <ToolbarButtons.Button
          text='Atualizar Custos Recorrentes Motoristas/Funcionários'
          icon='sync-alt'
          onClick={onUpdateReferenceSalaryDriversAndEmployees}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-5 mb-4'>
              <Autocomplete
                label='Fornecedor'
                searchDataSource={onSearchFornecedor}
                selectedItem={filtros.fornecedor}
                onSelectItem={(fornecedor) => {
                  setFiltros({
                    ...filtros,
                    fornecedor,
                    nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-5 mb-3'>
              <Textbox
                maxLength={20}
                label='Nr. Documento'
                text={filtros.nrTit}
                onChangedValue={(nrTit) =>
                  setFiltros({
                    ...filtros,
                    nrTit,
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-5 mb-4'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={filtros.empresa}
                onSelectItem={(empresa) => {
                  setFiltros({
                    ...filtros,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-5 mb-4'>
              <Autocomplete
                label='Tipo Despesa'
                searchDataSource={onSearchTipoDespesa}
                selectedItem={filtros.tipoDespesa}
                onSelectItem={(tipoDespesa) => {
                  setFiltros({
                    ...filtros,
                    tipoDespesa,
                    nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                  });
                }}
                dataSourceTextProperty='noTipoDespesa'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-5 mb-4'>
              <RadioButton
                label='Status'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.tipoAtivos}
                buttons={tipoAtivo}
                onChange={(tipoAtivos) => {
                  setFiltros({
                    ...filtros,
                    tipoAtivos,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
      <ModalGerarLancamentos
        show={modalShow.gerarLancamento}
        transaction={transaction}
        gerarLancamento={modalShow.gerarLancamento}
        onSave={(gerarLancamento) => {
          setData({ ...data, gerarLancamento });
          setModalShow({
            ...modalShow,
            gerarLancamento: modalShow.gerarLancamento,
          });
        }}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            gerarLancamento: false,
          });
          if (msg != null) {
            setMessage({
              message: msg,
              theme,
            });
          }
        }}
      />
      <ModalAlteracaoDeDia
        show={modalShow.alteracaoDia}
        transaction={transaction}
        alteracoesDia={modalShow.alteracoesDia}
        onSave={(alteracoesDia) => {
          setData({ ...data, alteracoesDia });
          setModalShow({
            ...modalShow,
            nrSeqCustoFixoS: modalShow.alteracoesDia,
          });
        }}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            alteracaoDia: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
    </CSDSelPage>
  );
}
