import React from 'react';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Textbox,
  Textarea,
  DatePicker,
  GridView,
  Button,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  getAlmoxarifadoLocalAutoComplete,
  getBalance,
  getCusto,
} from 'core/services/EST/almoxarifadoLocal';
import { getFuncionarioAutoComplete } from 'core/services/SEG/funcionario';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { getProdutoAutoCompleteByCodigos } from 'core/services/EST/produto';
import { getProdutoAgrupadorAutoCompleteDetalhado } from 'core/services/EST/produtoAgrupador';
import ManutencaoVeiculoProduto from 'core/models/FRO/manutencaoVeiculo_Produto';
import { getProdutoAgrupadorManutencao } from 'core/services/EST/produtoAgrupadorManutencao';

const ManutencaoInfosProdutos = ({
  setLoading,
  onSetMessage,
  prevDataRef,
  data,
  setData,
  dataProduto,
  setDataProduto,
  registraDataMovimento,
  preventivasList,
  validaSeExisteValor,
  gridViewProdutos,
  gridViewManutencaoPreventivaOnNew,
  findTransaction,
  onOpenTransaction,
  setModalShow,
}) => {
  const calculaVlrTotalProduto = (produto) => {
    if (produto.vlrDesconto === null || produto.vlrDesconto === undefined) {
      produto.vlrDesconto = 0;
    }

    if (produto.quantidade === null || produto.quantidade === undefined) {
      produto.quantidade = 0;
    }

    if (produto.vlrUnitario === null || produto.vlrUnitario === undefined) {
      produto.vlrUnitario = 0;
    }

    const valorTotalProduto =
      produto.quantidade * produto.vlrUnitario - produto.vlrDesconto;

    return valorTotalProduto;
  };

  const onSearchVeiculoForProdutos = async () => {
    const veiculos = [...data.placas];

    return veiculos;
  };

  const onSearchProdutoAgrupador = async (e) => {
    const {
      status,
      message: msg,
      data: produtoAgrupador,
    } = await getProdutoAgrupadorAutoCompleteDetalhado({
      noSearchGlobal: e,
    });

    if (msg) onSetMessage(status, msg);
    return produtoAgrupador;
  };

  const onSearchProdutos = async (nrSeqProdutoAgrupador) => {
    const {
      status,
      message: msg,
      produtos,
    } = await getProdutoAutoCompleteByCodigos({
      nrSeqProdutoAgrupador,
    });

    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchAlmoxarifado = async (nrSeqProduto) => {
    const { data: almoxarifados } = await getAlmoxarifadoAutoComplete({
      nrSeqProduto,
    });

    return almoxarifados;
  };

  const onSearchAlmoxarifadoLocal = async (nrSeqProduto, nrSeqAlmoxarifado) => {
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      nrSeqProduto,
      nrSeqAlmoxarifado,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const preencheCamposProduto = async (produtoAgrupador) => {
    setLoading(true);

    if (!data.pessoaOfi.flgControlaEstoque) {
      if (produtoAgrupador.nrSeqProdutoAgrupador) {
        const produtoCodigo = await onSearchProdutos(
          produtoAgrupador.nrSeqProdutoAgrupador
        );

        if (produtoCodigo.length > 1) {
          setDataProduto({
            ...dataProduto,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
          });
        } else {
          setDataProduto({
            ...dataProduto,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
            produto: produtoCodigo[0],
            nrSeqProduto: produtoCodigo[0].nrSeqProduto,
          });
        }
      }
    }

    if (data.pessoaOfi.flgControlaEstoque) {
      if (produtoAgrupador.nrSeqProdutoAgrupador) {
        const produtoCodigo = await onSearchProdutos(
          produtoAgrupador.nrSeqProdutoAgrupador
        );

        if (produtoCodigo.length > 1 || produtoCodigo.length === 0) {
          setDataProduto({
            veiculo: dataProduto.veiculo,
            nrSeqVeiculo: dataProduto.nrSeqVeiculo,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
          });
        } else {
          const almoxarifado = await onSearchAlmoxarifado(
            produtoCodigo[0].nrSeqProduto
          );

          if (almoxarifado.length === 0) {
            onSetMessage(
              2,
              'Esse Produto não tem local de armazenagem. Selecione outro produto.'
            );
          } else if (almoxarifado.length > 1) {
            setDataProduto({
              ...dataProduto,
              produtoAgrupador,
              nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
              produto: produtoCodigo[0],
              nrSeqProduto: produtoCodigo[0].nrSeqProduto,
            });
          } else {
            const almoxarifadoLocal = await onSearchAlmoxarifadoLocal(
              produtoCodigo[0].nrSeqProduto,
              almoxarifado[0].nrSeqAlmoxarifado
            );

            if (
              almoxarifadoLocal.length > 1 ||
              almoxarifadoLocal.length === 0
            ) {
              setDataProduto({
                ...dataProduto,
                produtoAgrupador,
                nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
                produto: produtoCodigo[0],
                nrSeqProduto: produtoCodigo[0].nrSeqProduto,
                almoxarifado: almoxarifado[0],
                nrSeqAlmoxarifado: almoxarifado[0].nrSeqAlmoxarifado,
              });
            } else {
              const saldoAtual = await getBalance(
                almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal
              );

              const custoUnitario = await getCusto(
                almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal,
                data.dtAbertura
              );

              setDataProduto({
                ...dataProduto,
                produtoAgrupador,
                nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
                produto: produtoCodigo[0],
                nrSeqProduto: produtoCodigo[0].nrSeqProduto,
                almoxarifado: almoxarifado[0],
                nrSeqAlmoxarifado: almoxarifado[0].nrSeqAlmoxarifado,
                estoque: saldoAtual,
                vlrUnitarioInicial: custoUnitario,
                vlrUnitario: custoUnitario,
                almoxarifadoLocal: almoxarifadoLocal[0],
                nrSeqAlmoxarifadoLocal:
                  almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal,
              });
            }
          }
        }
      } else {
        setDataProduto({
          veiculo: dataProduto.veiculo,
          produtos: null,
        });
      }
    }

    setLoading(false);
  };

  const onSearchFuncionario = async (e) => {
    const {
      status,
      message: msg,
      funcionarios,
    } = await getFuncionarioAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return funcionarios;
  };

  const afetaManutencaoPreventiva = async (produtoAgrupador) => {
    const afetaManutencao = await getProdutoAgrupadorManutencao(
      produtoAgrupador.nrSeqProdutoAgrupador
    );

    if (afetaManutencao) {
      const found = preventivasList.find(
        (item) =>
          item.manutencaoPreventiva.nrSeqManutencaoPreventiva ===
          afetaManutencao.nrSeqManutencaoPreventiva
      );

      if (found) {
        const grid = gridViewManutencaoPreventivaOnNew.current.getBuildedDataSource();
        grid.forEach((item) => {
          if (item[0].value === found.nrSeqVeiculo_ManutencaoPrevent) {
            item[0].state = true;
          }
        });
      }
    }
  };

  const onAdicionaProdutos = async () => {
    if (
      dataProduto.nrSeqVeiculo === undefined ||
      dataProduto.nrSeqVeiculo === null
    ) {
      onSetMessage(2, 'Obrigatório selecionar o veículo.');
    } else if (
      data.flgOficinaInterna &&
      registraDataMovimento === 'S' &&
      !dataProduto.produtoAgrupador?.produtoGrupo?.produtoGrupoTipo
        ?.flgServico &&
      !validaSeExisteValor(dataProduto.dtMovimento)
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Obrigatório selecionar a data da movimentação do produto.'
      );
    } else if (
      data.flgOficinaInterna &&
      (dataProduto.nrSeqPessoaFun === undefined ||
        dataProduto.nrSeqVeiculo === null)
    ) {
      onSetMessage(2, 'Obrigatório selecionar o funcionário.');
    } else if (
      dataProduto.quantidade <= 0 ||
      dataProduto.quantidade === undefined
    ) {
      onSetMessage(2, 'Insira uma quantidade do produto.');
    } else if (
      dataProduto.produtoAgrupador.produtoGrupo.produtoGrupoTipo.flgServico ===
        false &&
      dataProduto.vlrUnitario <= 0
    ) {
      onSetMessage(2, 'Valor do produto não pode ser zero.');
    } else if (
      data.flgOficinaInterna &&
      dataProduto.produtoAgrupador.produtoGrupo.produtoGrupoTipo.flgServico ===
        false &&
      (dataProduto.estoque <= 0 || dataProduto.estoque === undefined)
    ) {
      onSetMessage(2, 'Valor de estoque não pode ser 0 ou negativo.');
    } else if (dataProduto.produto.nrSeqProduto != null) {
      setLoading(true);

      let statusStr = '';

      if (!dataProduto.status || dataProduto.status === 'Inserir') {
        statusStr = 'Inserir';
      } else {
        statusStr = 'Alterar';
      }

      const valorTotalProduto = calculaVlrTotalProduto(dataProduto);

      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

      const produtosLista = data.produtos;
      const produto = new ManutencaoVeiculoProduto({
        ...dataProduto,
        vlrTotal: valorTotalProduto,
        status: statusStr,
        usuario: sessionUser,
        nrSeqUsuarioCad: sessionUser.nrSeqUsuario,
      });

      produtosLista.push(produto);

      await afetaManutencaoPreventiva(dataProduto.produtoAgrupador);

      if (gridViewProdutos && gridViewProdutos.current) {
        gridViewProdutos.current.setDataSource(produtosLista);
      }

      const somaProdutos = produtosLista.reduce(
        (accumulator, currentValue) => accumulator + currentValue.vlrTotal,
        0
      );

      setData({
        ...data,
        produtos: produtosLista,
        vlrSomaProdutos: somaProdutos,
      });
      setDataProduto({ dtMovimento: null, obs: '' });

      setLoading(false);
    }
  };

  const onClickEditProduto = async (selectedValue, datasource) => {
    setLoading(true);

    const obj = datasource.find((item) => item === selectedValue);

    if (obj.status === 'Inserir') {
      obj.status = GridView.EnumStatus.Inserir;
    } else {
      obj.status = GridView.EnumStatus.Alterar;
    }

    const produtos = datasource.filter((peca) => peca !== selectedValue);

    if (gridViewProdutos && gridViewProdutos.current) {
      gridViewProdutos.current.setDataSource(produtos);
    }

    const prevArray = prevDataRef.current;
    setData({ ...prevArray, produtos });

    if (prevArray.flgOficinaInterna) {
      const saldoAtual = await getBalance(obj.nrSeqAlmoxarifadoLocal);

      let custoUnitario = obj.vlrUnitario;
      if (obj.status !== GridView.EnumStatus.Alterar) {
        custoUnitario = await getCusto(
          obj.nrSeqAlmoxarifadoLocal,
          prevArray.dtAbertura
        );
      }

      setDataProduto({
        ...obj,
        estoque: saldoAtual,
        vlrUnitario: custoUnitario,
      });
    } else {
      setDataProduto({ ...obj });
    }

    setLoading(false);
  };

  const onClickRemoveProduto = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    if (gridViewProdutos && gridViewProdutos.current) {
      gridViewProdutos.current.setDataSource(datasource);
    }

    const somaProdutos = datasource.reduce(
      (accumulator, currentValue) => accumulator + currentValue.vlrTotal,
      0
    );

    const dataArray = prevDataRef.current;
    setData({
      ...dataArray,
      vlrSomaProdutos: somaProdutos,
      produtos: datasource,
    });
  };

  const columnsProdutosOnNew = [
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'nf.nrForNf', title: 'Nota Fiscal' },
    { key: 'produto.produtoCodigo.cdPeca', title: 'Cód. Peça' },
    { key: 'produtoAgrupador.noProduto', title: 'Produto Agrupador' },
    { key: 'produto.noProduto', title: 'Produto' },
    {
      key: 'vlrUnitario',
      title: 'Vlr. Unit.',
      format: GridView.DataTypes.Money,
    },
    { key: 'quantidade', title: 'Qtde' },
    { key: 'vlrDesconto', title: 'Desc.', format: GridView.DataTypes.Money },
    { key: 'vlrTotal', title: 'Total', format: GridView.DataTypes.Money },
    { key: 'funcionario.noPessoa', title: 'Funcionário' },
    { key: 'usuario.noLogin', title: 'Usuário Cad.' },
    {
      key: 'dtMovimento',
      title: 'Data Movimento',
      format: GridView.DataTypes.Date,
    },

    { key: 'obs', title: 'Obs.' },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProduto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  const onClickAplicarDesconto = async (vlrDesconto, produtos) => {
    if (data.vlrDescontoTotal > 100) {
      onSetMessage(2, 'O desconto não pode ser maior que 100%.');
    } else {
      setLoading(true);

      const desc = vlrDesconto / 100;

      produtos.forEach((prod) => {
        prod.vlrTotal = prod.vlrUnitario * prod.quantidade;
        prod.vlrDesconto = prod.vlrTotal * desc;
        prod.vlrTotal -= prod.vlrDesconto;
        prod.status = 'Alterar';
      });

      const somaProdutos = produtos.reduce(
        (accumulator, currentValue) => accumulator + currentValue.vlrTotal,
        0
      );

      setData({ ...data, produtos, vlrSomaProdutos: somaProdutos });

      if (gridViewProdutos && gridViewProdutos.current) {
        gridViewProdutos.current.setDataSource(produtos);
      }

      setLoading(false);
    }
  };

  return (
    <>
      {data.flgOficinaInterna && (
        <Panel className='mb-3'>
          <Panel.Header title='Produtos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='*Placa'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchVeiculoForProdutos}
                  selectedItem={dataProduto?.veiculo}
                  onSelectItem={(veiculo) => {
                    setDataProduto({
                      ...dataProduto,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>

              <div className='col mb-3'>
                <Autocomplete
                  label='Produto Agrupador'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchProdutoAgrupador}
                  findTransaction={findTransaction}
                  onOpenTransaction={onOpenTransaction}
                  transactionToOpen={TransacationCodes.Produto}
                  nrseqTransactionToOpen='nrSeqProduto'
                  selectedItem={dataProduto?.produtoAgrupador}
                  onSelectItem={async (produtoAgrupador) => {
                    if (Object.keys(produtoAgrupador).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        produtoAgrupador: null,
                        nrSeqProdutoAgrupador: null,
                        produto: null,
                        nrSeqProduto: null,
                        almoxarifado: null,
                        nrSeqAlmoxarifado: null,
                        noAlmoxarifado: null,
                        estoque: null,
                        vlrUnitario: null,
                        almoxarifadoLocal: null,
                        nrSeqAlmoxarifadoLocal: null,
                        noAlmoxarifadoLocal: null,
                      });
                    } else {
                      await preencheCamposProduto(produtoAgrupador);
                    }
                  }}
                  dataSourceTextProperty='noProdutoAgrupador'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Cód. Interno - Produto - Marca - Cód. Peça:'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={() =>
                    onSearchProdutos(dataProduto.nrSeqProdutoAgrupador)
                  }
                  selectedItem={dataProduto?.produto}
                  onSelectItem={(produto) => {
                    if (Object.keys(produto).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        almoxarifado: null,
                        nrSeqAlmoxarifado: null,
                        noAlmoxarifado: null,
                        estoque: null,
                        vlrUnitario: null,
                        almoxarifadoLocal: null,
                        nrSeqAlmoxarifadoLocal: null,
                        noAlmoxarifadoLocal: null,
                      });
                    } else {
                      setDataProduto({
                        ...dataProduto,
                        produto,
                        nrSeqProduto: produto.nrSeqProduto,
                      });
                    }
                  }}
                  dataSourceTextProperty='noProduto'
                />
              </div>

              <div className='col-5 mb-3'>
                <Autocomplete
                  label='Almoxarifado'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={() =>
                    onSearchAlmoxarifado(dataProduto.nrSeqProduto)
                  }
                  selectedItem={dataProduto.almoxarifado}
                  onSelectItem={(almoxarifado) => {
                    if (Object.keys(almoxarifado).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        estoque: null,
                        vlrUnitario: null,
                        almoxarifadoLocal: null,
                        nrSeqAlmoxarifadoLocal: null,
                        noAlmoxarifadoLocal: null,
                      });
                    } else {
                      setDataProduto({
                        ...dataProduto,
                        almoxarifado,
                        nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                        noAlmoxarifado: almoxarifado.noAlmoxarifado,
                      });
                    }
                  }}
                  dataSourceTextProperty='noAlmoxarifado'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Local'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={() =>
                    onSearchAlmoxarifadoLocal(
                      dataProduto.nrSeqProduto,
                      dataProduto.nrSeqAlmoxarifado
                    )
                  }
                  selectedItem={dataProduto?.almoxarifadoLocal}
                  onSelectItem={async (almoxarifadoLocal) => {
                    setLoading(true);

                    if (Object.keys(almoxarifadoLocal).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        estoque: null,
                        vlrUnitario: null,
                        almoxarifadoLocal: null,
                        nrSeqAlmoxarifadoLocal: null,
                        noAlmoxarifadoLocal: null,
                      });
                    } else {
                      const saldoAtual = await getBalance(
                        almoxarifadoLocal.nrSeqAlmoxarifadoLocal
                      );

                      const custoUnitario = await getCusto(
                        almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                        data.dtAbertura
                      );

                      setDataProduto({
                        ...dataProduto,
                        estoque: saldoAtual,
                        vlrUnitario: custoUnitario,
                        almoxarifadoLocal,
                        nrSeqAlmoxarifadoLocal:
                          almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                        noAlmoxarifadoLocal:
                          almoxarifadoLocal.noAlmoxarifadoLocal,
                      });
                    }

                    setLoading(false);
                  }}
                  dataSourceTextProperty='noAlmoxarifadoLocal'
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Quantidade'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto?.quantidade}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(quantidade) =>
                    setDataProduto({
                      ...dataProduto,
                      quantidade,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Estoque'
                  maxLength={20}
                  readOnly
                  text={dataProduto?.estoque}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(estoque) =>
                    setDataProduto({
                      ...dataProduto,
                      estoque,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Valor unitário'
                  maxLength={20}
                  readOnly={dataProduto.vlrUnitarioInicial > 0}
                  text={dataProduto?.vlrUnitario}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrUnitario) =>
                    setDataProduto({
                      ...dataProduto,
                      vlrUnitario,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Desconto'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto?.vlrDesconto}
                  mask={MaskTypes.Currency}
                  onChangedValue={(vlrDesconto) =>
                    setDataProduto({
                      ...dataProduto,
                      vlrDesconto,
                    })
                  }
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Funcionário'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchFuncionario}
                  selectedItem={dataProduto?.funcionario}
                  onSelectItem={(funcionario) => {
                    setDataProduto({
                      ...dataProduto,
                      funcionario,
                      nrSeqPessoaFun: funcionario.nrSeqPessoaFun,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              {/* Data de Movimentação do Produto */}
              {registraDataMovimento === 'S' && (
                <div className='col-2 mb-3'>
                  <DatePicker
                    readOnly={data?.flgStatus === 'Concluído'}
                    label='Data Movimento'
                    text={dataProduto?.dtMovimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtMovimento) =>
                      setDataProduto({ ...dataProduto, dtMovimento })
                    }
                  />
                </div>
              )}

              <div className='col mb-3'>
                <Textarea
                  label='Observações'
                  maxLength={1000}
                  readOnly={data?.flgStatus === 'Concluído'}
                  text={dataProduto?.obs}
                  onChangedValue={(obs) =>
                    setDataProduto({ ...dataProduto, obs })
                  }
                />
              </div>

              <div className='col-2 btn-group mr-2'>
                <Button
                  text='Adicionar'
                  className='mb-3 mt-4'
                  icon='plus'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  template={Button.Templates.Default}
                  onClick={() => {
                    onAdicionaProdutos();

                    if (
                      dataProduto.produtoAgrupador.produtoGrupo.flgControlaPneu
                    ) {
                      setModalShow({
                        showVincularPneuVeiculo: true,
                        pneuVeiculo: { nrSeqVeiculo: dataProduto.nrSeqVeiculo },
                      });
                    }
                  }}
                  outline
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewProdutos}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsProdutosOnNew}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>

            {data?.produtos?.length > 0 && (
              <div className='row'>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Soma dos Produtos'
                    required
                    readOnly
                    text={data?.vlrSomaProdutos ?? 0}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrSomaProdutos) => {
                      if (data.vlrSomaProdutos === null) {
                        data.vlrSomaProdutos = 0;
                      }
                      setData({
                        ...data,
                        vlrSomaProdutos,
                      });
                    }}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Desconto dos Produtos em %'
                    readOnly={data?.flgStatus === 'Concluído'}
                    maxLength={20}
                    text={data?.vlrDescontoTotal}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrDescontoTotal) =>
                      setData({
                        ...data,
                        vlrDescontoTotal,
                      })
                    }
                  />
                </div>

                <div className='col-2 btn-group mr-2'>
                  <Button
                    text='Aplicar'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    onClick={() =>
                      onClickAplicarDesconto(
                        data.vlrDescontoTotal,
                        data.produtos
                      )
                    }
                    outline
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      )}

      {!data.flgOficinaInterna && (
        <Panel className='mb-3'>
          <Panel.Header title='Produtos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='*Placa'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchVeiculoForProdutos}
                  selectedItem={dataProduto.veiculo}
                  onSelectItem={(veiculo) => {
                    setDataProduto({
                      ...dataProduto,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>

              <div className='col mb-3'>
                <Autocomplete
                  label='Produto Agrupador'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchProdutoAgrupador}
                  findTransaction={findTransaction}
                  onOpenTransaction={onOpenTransaction}
                  transactionToOpen={TransacationCodes.Produto}
                  nrseqTransactionToOpen='nrSeqProduto'
                  selectedItem={dataProduto.produtoAgrupador}
                  onSelectItem={async (produtoAgrupador) => {
                    if (Object.keys(produtoAgrupador).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        produtoAgrupador: null,
                      });
                    } else {
                      preencheCamposProduto(produtoAgrupador);
                    }
                  }}
                  dataSourceTextProperty='noProdutoAgrupador'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Cód. Interno - Produto - Marca - Cód. Peça:'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={() =>
                    onSearchProdutos(dataProduto.nrSeqProdutoAgrupador)
                  }
                  selectedItem={dataProduto.produto}
                  onSelectItem={(produto) => {
                    setDataProduto({
                      ...dataProduto,
                      produto,
                      nrSeqProduto: produto.nrSeqProduto,
                    });
                  }}
                  dataSourceTextProperty='noProduto'
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Quantidade'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto.quantidade}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(quantidade) =>
                    setDataProduto({
                      ...dataProduto,
                      quantidade,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Valor unitário'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto.vlrUnitario}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrUnitario) =>
                    setDataProduto({
                      ...dataProduto,
                      vlrUnitario,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Desconto'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto.vlrDesconto}
                  mask={MaskTypes.Currency}
                  onChangedValue={(vlrDesconto) =>
                    setDataProduto({
                      ...dataProduto,
                      vlrDesconto,
                    })
                  }
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Textarea
                  label='Observações'
                  maxLength={1000}
                  readOnly={data?.flgStatus === 'Concluído'}
                  text={dataProduto.obs}
                  onChangedValue={(obs) =>
                    setDataProduto({ ...dataProduto, obs })
                  }
                />
              </div>

              <div className='col-2 btn-group mr-2'>
                <Button
                  text='Adicionar'
                  className='mb-3 mt-4'
                  icon='plus'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  template={Button.Templates.Default}
                  onClick={onAdicionaProdutos}
                  outline
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewProdutos}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsProdutosOnNew}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>

            {data?.produtos?.length > 0 && (
              <div className='row'>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Soma dos Produtos'
                    required
                    readOnly
                    text={data?.vlrSomaProdutos ?? 0}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrSomaProdutos) => {
                      if (data.vlrSomaProdutos === null) {
                        data.vlrSomaProdutos = 0;
                      }
                      setData({
                        ...data,
                        vlrSomaProdutos,
                      });
                    }}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Desconto dos Produtos em %'
                    readOnly={data?.flgStatus === 'Concluído'}
                    maxLength={20}
                    text={data.vlrDescontoTotal}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrDescontoTotal) =>
                      setData({
                        ...data,
                        vlrDescontoTotal,
                      })
                    }
                  />
                </div>

                <div className='col-2 btn-group mr-2'>
                  <Button
                    text='Aplicar'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    onClick={() =>
                      onClickAplicarDesconto(
                        data.vlrDescontoTotal,
                        data.produtos
                      )
                    }
                    outline
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      )}
    </>
  );
};

export default ManutencaoInfosProdutos;
