import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  JustifyContent,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Switch,
  Panel,
  GridView,
  ToolbarButtons,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAbastecimento,
  saveAbastecimento,
  getVeiculoAbastecimento,
  getAbastecimentoTipoDespesa,
  getPreenchePosto,
} from 'core/services/FRO/abastecimento';

import { getPocOrcamentoAutoComplete } from 'core/services/POC/pocOrcamento';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getPostoCombustivelAutoComplete } from 'core/services/FRO/postoCombustivel';
import { getTipoCombustivelList } from 'core/services/FRO/tipoCombustivel';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getPostoCombustivelTanqueList } from 'core/services/FRO/postoCombustivelTanque';
import { getPostoCombustivelBombaList } from 'core/services/FRO/postoCombustivelBomba';
import { getAcertoMotoristaList } from 'core/services/FIN/acertoMotorista';
import { getTipoDespesaList } from 'core/services/FIN';
import { setTransaction } from 'core/services/api';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  getRetornoGridPlanoConta,
  getTipoDespesaPlanoDeContaTituloPagar,
} from 'core/services/FIN/tipoDespesa_PlanoDeConta';
import { TipoDespesa } from 'core/models/FIN';
import ModalImportarAbastecimento from './modalImportarAbastecimento';
// import PostoCombustivel from 'core/models/FRO/postoCombustivel';

export default function AbastecimentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({
    flgTanqueCheio: true,
    flgTipoCombustiveis: false,
  });
  const [permiteAlterar, setPermiteAlterar] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();
  const gridViewTipoCombustiveis = useRef();
  const [modalShow, setModalShow] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTanqueCombustivel = async (e) => {
    const {
      status,
      message: msg,
      data: postoCombustivelTanque,
    } = await getPostoCombustivelTanqueList({
      noPostoCombustivelTanque: e,
      nrSeqPessoaPos: data.nrSeqPessoaPos,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return postoCombustivelTanque;
  };
  const onSearchPostoBomba = async (e) => {
    const {
      status,
      message: msg,
      data: postoCombustivelBomba,
    } = await getPostoCombustivelBombaList({
      cdBomba: e,
      nrSeqPostoCombustivelTanque: data.nrSeqPostoCombustivelTanque,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return postoCombustivelBomba;
  };

  const PreenchePosto = async () => {
    if (
      data.nrSeqAbastecimento === undefined ||
      data.nrSeqAbastecimento === null
    ) {
      if (data.nrSeqPessoaPos > 0) {
        const posto = await getPreenchePosto(data);

        if (
          posto.postoCombustivel.flgProprio === true ||
          posto.postoCombustivel.flgContasPagar === false
        ) {
          posto.flgAvistaAcerto = true;
        } else {
          posto.flgAvistaAcerto = false;
        }
        setData({ ...posto });
      }
    }
  };

  const Recalcula = async (tipoDespesa) => {
    if (tipoDespesa !== undefined) {
      data.tipoDespesa = tipoDespesa;
    }
    if (data.tipoDespesa === undefined) {
      data.tipoDespesa = new TipoDespesa();
    }
    if (data.tipoDespesa.nrSeqTipoDespesa > 0) {
      const { data: planoconta } = await getTipoDespesaPlanoDeContaTituloPagar({
        nrSeqTipoDespesa: data.tipoDespesa.nrSeqTipoDespesa,
      });
      if (data.flgTipoCombustiveis !== true) {
        for (let i = 0; i < planoconta.length; ) {
          planoconta[i].vlrTitulo = data.vlrTotal;
          i += 1;
        }
      } else {
        let somaDosTitulos = 0;

        const TipoCombustiveis = gridViewTipoCombustiveis.current
          ? gridViewTipoCombustiveis.current.getDataSource()
          : [];

        if (TipoCombustiveis !== null && TipoCombustiveis !== undefined) {
          for (let i = 0; i < TipoCombustiveis.length; ) {
            somaDosTitulos += TipoCombustiveis[i].vlrTotal;
            i += 1;
          }

          for (let i = 0; i < planoconta.length; ) {
            planoconta[i].vlrTitulo = somaDosTitulos;
            i += 1;
          }
        }
      }
      if (planoconta.length > 0) {
        if (data.tipoDespesa.planoDeContas.length === 0) {
          data.tipoDespesa.planoDeContas.push(...planoconta);
        } else {
          for (let i = 0; i < data.tipoDespesa.planoDeContas.length; ) {
            data.tipoDespesa.planoDeContas[i] = planoconta.vlrPlanoConta;
            i += 1;
          }
        }
        const obj = await getRetornoGridPlanoConta(planoconta);
        if (gridView && gridView.current) gridView.current.setDataSource(obj);
      } else if (gridView && gridView.current)
        gridView.current.setDataSource(null);
    } else if (gridView && gridView.current)
      gridView.current.setDataSource(null);
  };

  const BuscaVeiculoTotal = async (veiculo, obj) => {
    if (veiculo === null) {
      veiculo = data.veiculo;
    } else {
      data.veiculo = veiculo;
      data.nrSeqVeiculo = veiculo.nrSeqVeiculo;
    }
    if (data.veiculo != null) {
      const veiculos = await getVeiculoAbastecimento(data);
      setData({
        tipoDespesa: obj.tipoDespesa,
        nrSeqTipoDespesa: obj.nrSeqTipoDespesa,
        vlrLitro: 0,
        qtdeLitro: 0,
        vlrTotal: 0,
        nrSeqVeiculo: transaction.setValues.nrSeqVeiculoPrinc,
        veiculo: transaction.setValues.veiculo,
        nrSeqPessoaMot: veiculos.nrSeqPessoaMot,
        motorista: veiculos.motorista,
        tipoCombustivel: veiculos.tipoCombustivel,
        nrSeqTipoCombustivel: veiculos.nrSeqTipoCombustivel,
        dtAbastecimento: veiculos.dtAbastecimento,
        flgTanqueCheio: true,
      });
    }
  };

  const BuscaVeiculo = async (veiculo) => {
    if (veiculo === null) {
      veiculo = data.veiculo;
    } else {
      data.veiculo = veiculo;
      data.nrSeqVeiculo = veiculo.nrSeqVeiculo;
    }
    if (data.nrSeqVeiculo > 0) {
      if (data.veiculo != null) {
        const veiculos = await getVeiculoAbastecimento(data);

        setData({
          ...data,
          nrSeqPessoaMot: veiculos.nrSeqPessoaMot,
          motorista: veiculos.motorista,
          veiculo: veiculos.veiculo,
          nrSeqVeiculo: veiculo.nrSeqVeiculo,
          nrSeqTipoCombustivel: veiculos.nrSeqTipoCombustivel,
          tipoCombustivel: veiculos.tipoCombustivel,
          dtAbastecimento: veiculos.dtAbastecimento,
        });
      }
    }
  };

  const loadTipoDespesa = useCallback(async () => {
    if (data.nrSeqPessoaPos > 0) {
      PreenchePosto();

      if (data.postoCombustivel.flgContasPagar === true) {
        if (data.nrSeqTipoDespesa > 0) {
          Recalcula(data.tipoDespesa);
        } else {
          const obj = await getAbastecimentoTipoDespesa(1);
          if (obj.tipoDespesa?.nrSeqTipoDespesa > 0) {
            setData((prev) => ({
              ...prev,
              nrSeqTipoDespesa: obj.nrSeqTipoDespesa,
              tipoDespesa: obj.tipoDespesa,
            }));

            Recalcula(obj.tipoDespesa);
          }
        }
      } else {
        setData((prev) => ({
          ...prev,
          nrSeqTipoDespesa: null,
          tipoDespesa: null,
        }));

        if (gridView && gridView.current) gridView.current.setDataSource(null);
      }
    } else if (data.nrSeqTipoDespesa > 0) {
      setData({
        ...data,
        flgAvistaAcerto: false,
      });
      Recalcula(data.tipoDespesa);
    } else {
      setData({
        ...data,
        nrSeqTipoDespesa: null,
        tipoDespesa: null,
        flgAvistaAcerto: false,
      });
      if (gridView && gridView.current) gridView.current.setDataSource(null);
    }
  }, [data.nrSeqTipoDespesa, data.nrSeqPessoaPos]);

  const handlePermiteAlterar = (obj) => {
    if (obj.nrSeqTituloPagar > 0) {
      setPermiteAlterar(false);
    } else if (obj.nrSeqAcertoMotorista > 0) {
      setPermiteAlterar(false);
    } else {
      setPermiteAlterar(true);
    }
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj = await getAbastecimento(registryKey);

      handlePermiteAlterar(obj);

      if (obj.nrSeqAcertoMotorista > 0) {
        obj.flgVinculaAcerto = true;

        if (obj.acertoMotorista !== undefined) {
          obj.acertoMotorista.diaAcerto = `${new Date(
            obj.acertoMotorista.dtSaida
          ).toLocaleDateString()} - ${new Date(
            obj.acertoMotorista.dtChegada
          ).toLocaleDateString()} `;
        }
      }

      if (obj.nrSeqTituloPagar > 0) {
        setData({ ...obj });
        onSetMessage(
          ResponseStatus.Error,
          'Abastecimento com titulo a pagar vinculado, só sera possivel editar o odômetro e a data do abastecimento '
        );
      } else if (
        obj.nrSeqTituloPagar === undefined &&
        obj.flgAvistaAcerto === false
      ) {
        setData({ ...obj });
        onSetMessage(
          ResponseStatus.Error,
          'Abastecimento abaixo, esta marcado como a prazo, mas não gerado titulo a pagar, pois o posto esta marcado para não gerar titulo. '
        );
      } else if (
        obj.nrSeqTituloPagar === null &&
        obj.flgAvistaAcerto === false
      ) {
        setData({ ...obj });
        onSetMessage(
          ResponseStatus.Error,
          'Abastecimento abaixo, esta marcado como a prazo, mas não gerado titulo a pagar, pois o posto esta marcado para não gerar titulo. '
        );
      } else if (obj.nrSeqAcertoMotorista > 0) {
        setData({ ...obj });
        onSetMessage(
          ResponseStatus.Error,
          'Abastecimento com acerto motorista vinculado, só sera possivel editar o odômetro e a data do abastecimento '
        );
      } else {
        setData({ ...obj, nrSeqTituloPagar: undefined });
      }

      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.tituloPagarPlanoDeConta);

      setLoading(false);
    } else {
      if (gridView && gridView.current) gridView.current.setDataSource(null);

      const obj = await getAbastecimentoTipoDespesa(1);

      if (
        transaction.setValues !== null &&
        transaction.setValues !== undefined
      ) {
        BuscaVeiculoTotal(transaction.setValues.veiculo, obj);
      } else {
        setData({
          tipoDespesa: obj.tipoDespesa,
          nrSeqTipoDespesa: obj.nrSeqTipoDespesa,
          vlrLitro: 0,
          qtdeLitro: 0,
          vlrTotal: 0,
          flgTanqueCheio: true,
          flgTipoCombustiveis: false,
        });
      }
      if (obj.tipoDespesa?.nrSeqTipoDespesa > 0) {
        Recalcula(obj.tipoDespesa);
      }

      setPermiteAlterar(true);

      setMessage(null);
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  useEffect(() => {
    (async function func() {
      await loadTipoDespesa();
    })();
  }, [data.nrSeqPessoaPos]);

  useEffect(() => {
    (async function func() {
      if (data.flgTipoCombustiveis) {
        onSetMessage(
          ResponseStatus.Error,
          'O abastecimento abaixo gerara somente um titulo a pagar com o valor total dos titulos, independente dos varios itens adicionados. '
        );
      }
    })();
  }, [data.flgTipoCombustiveis]);

  const save = async () => {
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const despesa = gridView.current ? gridView.current.getDataSource() : [];

    let TipoCombustiveis = [];

    if (data.flgTipoCombustiveis) {
      TipoCombustiveis = gridViewTipoCombustiveis.current
        ? gridViewTipoCombustiveis.current.getDataSource()
        : [];
    }

    const obj = {
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      tituloPagarPlanoDeConta: despesa,
      abastecimentoS: TipoCombustiveis,
    };

    if (obj.tituloPagarPlanoDeConta !== null) {
      for (let i = 0; i < obj.tituloPagarPlanoDeConta.length; ) {
        obj.tituloPagarPlanoDeConta[i].vlrTitulo =
          obj.tituloPagarPlanoDeConta[i].vlrPlanoConta;
        i += 1;
      }
    }

    const { status, message: msg } = await saveAbastecimento(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(
      status === ResponseStatus.Success
        ? { vlrLitro: 0, qtdeLitro: 0, vlrTotal: 0 }
        : data
    );
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const savePrice = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTipoDespesa_PlanoDeConta === keyValue
    );
    itemParcela.vlrPlanoConta = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqTipoDespesa_PlanoDeConta ===
        itemParcela.nrSeqTipoDespesa_PlanoDeConta
      ) {
        datasource[i].vlrPlanoConta = itemParcela.vlrPlanoConta;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const fPrice = function (keyValue, value) {
    savePrice(keyValue, value);
  };

  const columns = [
    {
      key: 'nrSeqTipoDespesa_PlanoDeConta',
      visible: false,
    },
    {
      key: 'planoDeContaCre.cdCaminhoCompleto',
      title: 'Plano De Contas Credito',
    },
    {
      key: 'vlrPlanoConta',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      readOnlyDynamic: 'flgPercentual',
      onBlur: fPrice,
      maxLength: 10,
    },
    { key: 'flgPercentual', visible: false },
  ];

  const onRemoveAbastecimento = async (selectedValue, datasource) => {
    let documentos = [];

    documentos = datasource.filter((Documento) => Documento !== selectedValue);

    if (gridViewTipoCombustiveis && gridViewTipoCombustiveis.current)
      gridViewTipoCombustiveis.current.setDataSource(documentos);
  };

  const columnsTipoCombustivel = [
    {
      key: 'nrSeqTipoCombustivel',
      visible: false,
    },
    {
      key: 'tipoCombustivel.noTipoCombustivel',
      title: 'Tipo Combustivel',
    },
    {
      key: 'vlrLitro',
      title: 'Valor Litro',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'qtdeLitro',
      title: 'Qtde Litro',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Valor Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqAbastecimento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveAbastecimento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const PostoCombustivel = async (postoCombustivel) => {
    if (postoCombustivel !== undefined) {
      data.postoCombustivel = postoCombustivel;
    }

    if (data.postoCombustivel !== undefined && data.postoCombustivel !== null) {
      if (
        data.postoCombustivel.flgContasPagar === false &&
        data.postoCombustivel.flgProprio === false
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'Posto de combustivel cadastrado para não gerar titulo a pagar, caso queira gerar, alterar no cadastro do posto'
        );
      }
    }
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      data: tipoDespesas,
    } = await getTipoDespesaList({
      noTipoDespesa: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const onSearchPessoaPos = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaPoss,
    } = await getPostoCombustivelAutoComplete({
      noPessoa: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaPoss;
  };

  const onSearchTipoCombustivel = async (e) => {
    const {
      status,
      message: msg,
      data: tipoCombustivels,
    } = await getTipoCombustivelList({
      noTipoCombustivel: e,
      nrSeqVeiculo: data.nrSeqVeiculo,
    });
    if (msg) onSetMessage(status, msg);
    return tipoCombustivels;
  };
  const onSearchAcertoMotorista = async (e) => {
    const {
      status,
      message: msg,
      data: acertoMotorista,
    } = await getAcertoMotoristaList({
      cdAcertoMotorista: e,
      nrSeqVeiculoPrinc: data.nrSeqVeiculo,
      dtChegada: data.dtAbastecimento,
      dtSaida: data.dtAbastecimento,
    });
    if (msg) onSetMessage(status, msg);
    if (acertoMotorista !== undefined) {
      if (acertoMotorista.length > 0) {
        for (let i = 0; i < acertoMotorista.length; ) {
          acertoMotorista[i].diaAcerto = `${new Date(
            acertoMotorista[i].dtSaida
          ).toLocaleDateString()} - ${new Date(
            acertoMotorista[i].dtChegada
          ).toLocaleDateString()} `;
          i += 1;
        }
      }
    }

    return acertoMotorista;
  };

  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onAdicionarAbastecimento = async () => {
    if (data.vlrLitro === undefined || data.vlrLitro === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem o valor do litro definido'
      );
      return;
    }
    if (data.qtdeLitro === undefined || data.qtdeLitro === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem a quantidade de litros'
      );
      return;
    }
    if (data.vlrTotal === undefined || data.vlrTotal === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem inserir o valor total'
      );
      return;
    }

    if (
      data.nrSeqTipoCombustivel === undefined ||
      data.nrSeqTipoCombustivel === null
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem inserir o Tipo Combustivel'
      );
      return;
    }

    let lista = gridViewTipoCombustiveis.current.getDataSource();
    if (lista === null) {
      lista = [];
    }
    if (data.status !== GridView.EnumStatus.Alterar) {
      data.status = GridView.EnumStatus.Inserir;
    }
    lista.push(data);
    if (gridViewTipoCombustiveis && gridViewTipoCombustiveis.current)
      gridViewTipoCombustiveis.current.setDataSource(lista);

    if (data.nrSeqTipoDespesa > 0) {
      Recalcula(data.tipoDespesa);
    }

    setData({
      ...data,
      vlrLitro: 0,
      vlrTotal: 0,
      qtdeLitro: 0,
      nrSeqTipoCombustivel: null,
      tipoCombustivel: null,
    });
  };

  const RecalculaValores = (id = '') => {
    if (data.vlrLitro === null || data.vlrLitro === undefined) {
      data.vlrLitro = 0;
    }
    if (data.vlrTotal === null || data.vlrTotal === undefined) {
      data.vlrTotal = 0;
    }
    if (data.qtdeLitro === null || data.qtdeLitro === undefined) {
      data.qtdeLitro = 0;
    }

    if (id === 'VlrLitro') {
      if (data.vlrLitro > 0) {
        if (data.qtdeLitro > 0) {
          let vlrtotal = data.vlrLitro * data.qtdeLitro;

          vlrtotal = vlrtotal.toFixed(2);

          vlrtotal = parseFloat(vlrtotal);

          setData({ ...data, vlrTotal: vlrtotal });

          data.vlrTotal = vlrtotal;
        } else if (data.vlrTotal > 0) {
          let qtdelitro = data.vlrTotal / data.vlrLitro;

          qtdelitro = qtdelitro.toFixed(2);

          qtdelitro = parseFloat(qtdelitro);

          setData({ ...data, qtdeLitro: qtdelitro });

          data.qtdeLitro = qtdelitro;
        }
      } else {
        setData({ ...data, vlrTotal: 0, vlrLitro: 0 });
        data.vlrTotal = 0;
      }
    } else if (id === 'VlrTotal') {
      if (data.vlrTotal > 0) {
        if (data.qtdeLitro > 0) {
          let vlrlitro = data.vlrTotal / data.qtdeLitro;

          vlrlitro = vlrlitro.toFixed(3);

          vlrlitro = parseFloat(vlrlitro);

          setData({ ...data, vlrLitro: vlrlitro });

          data.vlrlitro = vlrlitro;
        } else if (data.vlrLitro > 0) {
          let qtdelitro = data.vlrTotal / data.vlrLitro;

          qtdelitro = qtdelitro.toFixed(2);

          qtdelitro = parseFloat(qtdelitro);

          setData({ ...data, qtdeLitro: qtdelitro });

          data.qtdeLitro = qtdelitro;
        }
      } else {
        setData({ ...data, vlrTotal: 0, vlrLitro: 0 });
        data.vlrTotal = 0;
      }
    } else if (id === 'QtdeLitro') {
      if (data.qtdeLitro > 0) {
        if (data.vlrLitro > 0) {
          let vlrtotal = data.vlrLitro * data.qtdeLitro;

          vlrtotal = vlrtotal.toFixed(2);

          vlrtotal = parseFloat(vlrtotal);

          setData({ ...data, vlrTotal: vlrtotal });

          data.vlrTotal = vlrtotal;
        } else if (data.vlrTotal > 0) {
          let vlrlitro = data.vlrTotal / data.qtdeLitro;

          vlrlitro = vlrlitro.toFixed(3);

          vlrlitro = parseFloat(vlrlitro);

          setData({ ...data, vlrLitro: vlrlitro });

          data.vlrlitro = vlrlitro;
        }
      } else {
        setData({ ...data, vlrTotal: 0, qtdeLitro: 0 });
        data.vlrTotal = 0;
      }
    }

    if (data.tipoDespesa?.nrSeqTipoDespesa > 0) {
      Recalcula(data.tipoDespesa);
    }
  };
  const ValorString = async () => {
    if (data.hora !== undefined && data.hora !== null) {
      if (data.hora.length === 1) {
        const valor = `${data.hora}0:00:00`;
        setData({ ...data, hora: valor });
      }
      if (data.hora.length === 2) {
        const valor = `${data.hora}:00:00`;
        setData({ ...data, hora: valor });
      }
      if (data.hora.length === 3) {
        const resultado = data.hora.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}0:00`;
        setData({ ...data, hora: valor });
      }
      if (data.hora.length === 4) {
        const resultado = data.hora.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}${resultado[3]}:00`;

        setData({ ...data, hora: valor });
      }
      if (data.hora.length === 5) {
        const resultado = data.hora.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}${resultado[3]}:${resultado[4]}0`;

        setData({ ...data, hora: valor });
      }
    }
  };

  const onNew = async () => {
    if (gridView && gridView.current) gridView.current.setDataSource(null);

    const obj = await getAbastecimentoTipoDespesa(1);

    setData({
      tipoDespesa: obj.tipoDespesa,
      nrSeqTipoDespesa: obj.nrSeqTipoDespesa,
      vlrLitro: 0,
      qtdeLitro: 0,
      vlrTotal: 0,
      flgTanqueCheio: true,
      flgTipoCombustiveis: false,
    });
    if (obj.tipoDespesa?.nrSeqTipoDespesa > 0) {
      Recalcula(obj.tipoDespesa);
    }
    setPermiteAlterar(true);
    setMessage(null);
  };

  const onSearchPocOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: orcamentos,
    } = await getPocOrcamentoAutoComplete({
      cdProposta: e,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    return orcamentos;
  };

  const onOpenTituloPagar = (selectedValue) => {
    const transactionContaChamada = findTransaction(
      TransacationCodes.TituloPagar
    );

    if (transactionContaChamada) {
      transactionContaChamada.registryKey = selectedValue;

      onOpenTransaction(transactionContaChamada, true);
    }
  };

  const onOpenImportaNfs = async () => {
    setModalShow({
      ...modalShow,
      importaNfS: true,
    });
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Abastecimento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        {data.nrSeqTituloPagar > 0 && data.nrSeqAbastecimento > 0 && (
          <ToolbarButtons.Button
            text='Visualizar Titulo Gerado'
            icon='layer-group'
            onClick={() => onOpenTituloPagar(data.nrSeqTituloPagar)}
          />
        )}
        <ToolbarButtons.Button
          text='Importar Via NF-e'
          icon='fa-sharp fa-light fa-arrows-repeat'
          onClick={() => onOpenImportaNfs()}
        />
      </ToolbarButtons>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Veiculo'
            readOnly={!permiteAlterar}
            required
            searchDataSource={onSearchVeiculo}
            selectedItem={data.veiculo}
            onSelectItem={(veiculo) => {
              setData({
                ...data,
                veiculo,
                nrSeqVeiculo: veiculo.nrSeqVeiculo,
              });
              BuscaVeiculo(veiculo);
            }}
            dataSourceTextProperty='noVeiculo'
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Veiculo}
            nrseqTransactionToOpen='nrSeqVeiculo'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Posto'
            required
            readOnly={!permiteAlterar}
            searchDataSource={onSearchPessoaPos}
            selectedItem={data.postoCombustivel}
            onSelectItem={(postoCombustivel) => {
              setData({
                ...data,
                postoCombustivel,
                nrSeqPessoaPos: postoCombustivel.nrSeqPessoaPos,
              });
              PostoCombustivel(postoCombustivel);
            }}
            dataSourceTextProperty='noPessoa'
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.PostoCombustivel}
            nrseqTransactionToOpen='nrSeqPessoaPos'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Motorista'
            readOnly={!permiteAlterar}
            searchDataSource={onSearchPessoaMot}
            selectedItem={data.motorista}
            onSelectItem={(motorista) => {
              setData({
                ...data,
                motorista,
                nrSeqPessoaMot: motorista.nrSeqPessoaMot,
              });
            }}
            dataSourceTextProperty='noPessoa'
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Motorista}
            nrseqTransactionToOpen='nrSeqPessoaMot'
          />
        </div>
      </div>

      <div className='row'>
        {data?.veiculo?.veiculoModelo?.noTipoOdometro !== 'H' && (
          <div className='col-2 mb-3'>
            <Textbox
              label='Odômetro'
              required
              text={data.nrOdometro}
              mask={MaskTypes.Integer}
              onChangedValue={(nrOdometro) => setData({ ...data, nrOdometro })}
            />
          </div>
        )}
        {data?.veiculo?.veiculoModelo?.noTipoOdometro === 'H' && (
          <div className='col-2 mb-3'>
            <Textbox
              label='Horímetro'
              required
              text={data.nrHorimetro}
              mask={MaskTypes.DecimalCustom}
              decimalPlaces={1}
              onChangedValue={(nrHorimetro) =>
                setData({ ...data, nrHorimetro })
              }
            />
          </div>
        )}
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Mais de um combustivel'
            checked={data.flgTipoCombustiveis}
            onChange={(flgTipoCombustiveis) =>
              setData({ ...data, flgTipoCombustiveis })
            }
          />
        </div>

        {!data.flgTipoCombustiveis && (
          <div className='col-3 mb-3'>
            <Autocomplete
              label='Combustivel'
              readOnly={!permiteAlterar}
              searchDataSource={onSearchTipoCombustivel}
              selectedItem={data.tipoCombustivel}
              onSelectItem={(tipoCombustivel) => {
                setData({
                  ...data,
                  tipoCombustivel,
                  nrSeqTipoCombustivel: tipoCombustivel.nrSeqTipoCombustivel,
                });
              }}
              dataSourceTextProperty='noTipoCombustivel'
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              transactionToOpen={TransacationCodes.TipoCombustivel}
              nrseqTransactionToOpen='nrSeqTipoCombustivel'
            />
          </div>
        )}
        {data?.postoCombustivel?.flgProprio === true && (
          <div className='col-3 mb-3'>
            <Autocomplete
              label='Tanque'
              readOnly={!permiteAlterar}
              searchDataSource={onSearchTanqueCombustivel}
              selectedItem={data.postoCombustivelTanque}
              onSelectItem={(postoCombustivelTanque) => {
                setData({
                  ...data,
                  postoCombustivelTanque,
                  nrSeqPostoCombustivelTanque:
                    postoCombustivelTanque.nrSeqPostoCombustivelTanque,
                });
              }}
              dataSourceTextProperty='noPostoCombustivelTanque'
            />
          </div>
        )}

        {data?.postoCombustivel?.flgProprio === true && (
          <div className='col-2 mb-3'>
            <Autocomplete
              label='Bomba'
              readOnly={!permiteAlterar}
              searchDataSource={onSearchPostoBomba}
              selectedItem={data.postoBomba}
              onSelectItem={(postoBomba) => {
                setData({
                  ...data,
                  postoBomba,
                  nrSeqPostoBomba: postoBomba.nrSeqPostoBomba,
                });
              }}
              dataSourceTextProperty='cdBomba'
            />
          </div>
        )}
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data'
            readOnly={!permiteAlterar}
            required
            text={data.dtAbastecimento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAbastecimento) =>
              setData({ ...data, dtAbastecimento })
            }
          />
        </div>

        <div className='col-2 mb-3'>
          <Textbox
            label='Hora'
            readOnly={!permiteAlterar}
            text={data.hora}
            mask={MaskTypes.Time}
            onChangedValue={(hora) => setData({ ...data, hora })}
            onBlur={ValorString}
          />
        </div>

        <div className='col-4 mb-3'>
          <Textbox
            maxLength={44}
            label='Nr. Documento'
            id='txtDocumento'
            readOnly={!permiteAlterar}
            text={data.nrDocumento}
            onChangedValue={(nrDocumento) => setData({ ...data, nrDocumento })}
          />
        </div>

        <div className='col-1 mb-3'>
          <Switch
            formControl
            label='À Vista'
            checked={data.flgAvistaAcerto}
            onChange={(flgAvistaAcerto) =>
              setData({ ...data, flgAvistaAcerto })
            }
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Orçamento'
            id='txtOrcamento'
            searchDataSource={onSearchPocOrcamento}
            selectedItem={data.orcamento}
            onSelectItem={(orcamento) => {
              setData({
                ...data,
                orcamento,
                nrSeqPocOrcamento: orcamento.nrSeqPocOrcamento,
              });
            }}
            dataSourceTextProperty='cdProposta'
          />
        </div>
      </div>

      <div className='row'>
        {!data.flgAvistaAcerto && (
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              readOnly={!permiteAlterar}
              searchDataSource={onSearchFormaPagamento}
              selectedItem={data.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setData({
                  ...data,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              transactionToOpen={TransacationCodes.FormaPagamento}
              nrseqTransactionToOpen='nrSeqFormaPagamento'
            />
          </div>
        )}
        {!data?.flgAvistaAcerto && (
          <div className='col-2 mb-3'>
            <DatePicker
              label='Vencimento'
              readOnly={!permiteAlterar}
              text={data.dtVencimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
            />
          </div>
        )}
        {!data?.flgAvistaAcerto && (
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Tipo Despesa'
              readOnly={!permiteAlterar}
              searchDataSource={onSearchTipoDespesa}
              onSelectItem={async (tipoDespesa) => {
                setData({
                  ...data,
                  tipoDespesa,
                  nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                });
                Recalcula(tipoDespesa);
              }}
              selectedItem={data.tipoDespesa}
              dataSourceTextProperty='noTipoDespesa'
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              transactionToOpen={TransacationCodes.TipoDespesa}
              nrseqTransactionToOpen='nrSeqTipoDespesa'
            />
          </div>
        )}
      </div>

      <div className='row'>
        {!data.flgTipoCombustiveis && (
          <div className='col-3 mb-3'>
            <Textbox
              label='Valor Litro'
              readOnly={!permiteAlterar}
              text={data.vlrLitro}
              mask={MaskTypes.DecimalCustom}
              decimalPlaces={3}
              onChangedValue={(vlrLitro) => setData({ ...data, vlrLitro })}
              onBlur={() => RecalculaValores('VlrLitro')}
            />
          </div>
        )}
        {!data.flgTipoCombustiveis && (
          <div className='col-3 mb-3'>
            <Textbox
              label='Litragem'
              readOnly={!permiteAlterar}
              text={data.qtdeLitro}
              decimalPlaces={3}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(qtdeLitro) => setData({ ...data, qtdeLitro })}
              onBlur={() => RecalculaValores('QtdeLitro')}
            />
          </div>
        )}
        {!data.flgTipoCombustiveis && (
          <div className='col-3 mb-3'>
            <Textbox
              label='Valor Total'
              readOnly={!permiteAlterar}
              text={data.vlrTotal}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrTotal) => setData({ ...data, vlrTotal })}
              onBlur={() => RecalculaValores('VlrTotal')}
            />
          </div>
        )}
        <div className='col-3 mb-3'>
          <Switch
            formControl
            label='Tanque Cheio'
            checked={data.flgTanqueCheio}
            onChange={(flgTanqueCheio) => setData({ ...data, flgTanqueCheio })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Vincula Acerto'
            checked={data.flgVinculaAcerto}
            onChange={(flgVinculaAcerto) =>
              setData({ ...data, flgVinculaAcerto })
            }
          />
        </div>
        {data.flgVinculaAcerto && (
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Acerto Motorista'
              required
              searchDataSource={onSearchAcertoMotorista}
              selectedItem={data.acertoMotorista}
              onSelectItem={(acertoMotorista) => {
                setData({
                  ...data,
                  acertoMotorista,
                  nrSeqAcertoMotorista: acertoMotorista.nrSeqAcertoMotorista,
                });
              }}
              dataSourceTextProperty='diaAcerto'
            />
          </div>
        )}
        {data.nrSeqTituloPagar > 0 && (
          <div className='col-3'>
            <Textbox
              label='Cd Titulo Pagar'
              readOnly
              text={data.nrSeqTituloPagar}
            />
          </div>
        )}
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={250}
            readOnly={!permiteAlterar}
            label='Observação'
            text={data.noObs}
            onChangedValue={(noObs) => setData({ ...data, noObs })}
          />
        </div>
      </div>

      {data.flgTipoCombustiveis && (
        <div className='row'>
          <Panel>
            <Panel.Header
              title='Dados Combustivel'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />

            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3 mb-3'>
                  <Autocomplete
                    label='Combustivel'
                    readOnly={!permiteAlterar}
                    searchDataSource={onSearchTipoCombustivel}
                    selectedItem={data.tipoCombustivel}
                    onSelectItem={(tipoCombustivel) => {
                      setData({
                        ...data,
                        tipoCombustivel,
                        nrSeqTipoCombustivel:
                          tipoCombustivel.nrSeqTipoCombustivel,
                      });
                    }}
                    dataSourceTextProperty='noTipoCombustivel'
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.TipoCombustivel}
                    nrseqTransactionToOpen='nrSeqTipoCombustivel'
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Valor Litro'
                    readOnly={!permiteAlterar}
                    text={data.vlrLitro}
                    mask={MaskTypes.DecimalCustom}
                    decimalPlaces={3}
                    onChangedValue={(vlrLitro) =>
                      setData({ ...data, vlrLitro })
                    }
                    onBlur={() => RecalculaValores('VlrLitro')}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Litragem'
                    readOnly={!permiteAlterar}
                    text={data.qtdeLitro}
                    decimalPlaces={3}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(qtdeLitro) =>
                      setData({ ...data, qtdeLitro })
                    }
                    onBlur={() => RecalculaValores('QtdeLitro')}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Valor Total'
                    readOnly={!permiteAlterar}
                    text={data.vlrTotal}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrTotal) =>
                      setData({ ...data, vlrTotal })
                    }
                    onBlur={() => RecalculaValores('VlrTotal')}
                  />
                </div>
                <div className='col-2 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='plus'
                    text='Adicionar'
                    onClick={onAdicionarAbastecimento}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewTipoCombustiveis}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsTipoCombustivel}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}

      {!data?.flgAvistaAcerto && (
        <div className='row'>
          <div className='col-12'>
            <Panel>
              <Panel.Header
                title='Rateio do plano de Contas'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-9'>
                    <GridView
                      ref={gridView}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}

      <ModalImportarAbastecimento
        show={modalShow.importaNfS}
        onSucessImport={(abastecimento) => {
          setModalShow({
            ...modalShow,
            importaNfS: false,
          });
          setMessage({
            message: abastecimento.msg,
            theme: abastecimento.theme,
          });
          if (abastecimento.value.abastecimentoS.length > 0) {
            setData({
              ...abastecimento.value.abastecimentoS[0],
              flgTipoCombustiveis: true,
              flgTanqueCheio: true,
            });

            if (gridViewTipoCombustiveis && gridViewTipoCombustiveis.current)
              gridViewTipoCombustiveis.current.setDataSource(
                abastecimento.value.abastecimentoS
              );
          }
        }}
        onClose={() => {
          setModalShow({
            ...modalShow,
            importaNfS: false,
          });
        }}
      />
    </CSDManutPage>
  );
}
