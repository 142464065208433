import qs from 'qs';
import Pagination from 'core/models/pagination';
import Demanda from 'core/models/HELP/demanda';
import HoraAgendamentoPortabilidade from 'core/models/HELP/horaAgendamentoPortabilidade';
import { MimeTypes } from 'ui/Helpers/utils';
import api from '../api';

const baseURL = `/HELP/Demanda`;

export const cancelDemand = async (id) => {
  const response = await api.get(`${baseURL}/CancelaDemanda`, {
    params: { id },
  });
  return response.data ?? {};
};

export const postGeraContrato = async (data) => {
  const response = await api.post(`${baseURL}/GeraContrato`, data);

  return {
    status: response.data.status,
    message: response.data.message,
    demanda: response.data.value ? new Demanda(response.data.value) : {},
  };
};

export const postGeraContratoMassivo = async (data) => {
  const response = await api.post(`${baseURL}/GeraContratoMassivo`, data);

  return {
    status: response.data.status,
    message: response.data.message,
    demanda: response.data.value ? new Demanda(response.data.value) : {},
  };
};

export const getDemandaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    demandas: value?.map((json) => new Demanda(json)) ?? [],
  };
};

export const getDemandaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Demanda(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getDemandaPaginedWithTeleCall = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginedWithTeleCall`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Demanda(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getDemandasDevolvidasPaginated = async (filters) => {
  const response = await api.get(
    `${baseURL}/SearchDemandasDevolvidasPaginated`,
    {
      params: filters,
    }
  );
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Demanda(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getGraficoDemandasDevolvidaInfo = async (filters) => {
  const response = await api.get(`${baseURL}/GetGraficoDemandasDevolvidaInfo`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};

export const getDemanda = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Demanda(value) : {};
};

export const saveDemanda = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return {
    status: response.data.status,
    message: response.data.message,
    demanda: response.data.value ? new Demanda(response.data.value) : {},
  };
};

export const saveDemandaMassiva = async (data) => {
  const response = await api.post(`${baseURL}/SaveDemandaMassiva`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    demanda: value ? new Demanda(value) : {},
  };
};

export const confirmDemand = async (data) => {
  const response = await api.post(`${baseURL}/ConfirmDemand`, data);
  return response.data ?? {};
};

export const returnDemand = async (data) => {
  const response = await api.post(`${baseURL}/ReturnDemand`, data);
  return response.data ?? {};
};

export const devolveDemanda = async (data) => {
  const response = await api.post(`${baseURL}/DevolveDemanda`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    demanda: value ? new Demanda(value) : {},
  };
};

export const deleteDemanda = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printDemanda = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelDemanda = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllDemanda = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const postSolicitaMVNO = async (id) => {
  const response = await api.get(`${baseURL}/SolicitaDemandaMVNO`, {
    params: { id },
  });
  return response.data ?? {};
};

export const getScheduledDate = async (data) => {
  const response = await api.get(`${baseURL}/GetScheduledDate`, {
    params: data,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    agenda: new HoraAgendamentoPortabilidade(value ?? {}),
  };
};

export const postPortability = async (data) => {
  const response = await api.post(`${baseURL}/Portability`, data);
  return response.data ?? {};
};

export const geraTT = async (id) => {
  const response = await api.get(`${baseURL}/GeraTT`, {
    params: { id },
  });
  return response.data ?? {};
};

export const SendEmail = async (ids, email) => {
  const response = await api.get(`${baseURL}/SendEmail`, {
    params: { ids, email },
    paramsSerializer: (params) => qs.stringify(params),
  });

  const { status, message } = response.data;

  return {
    status,
    message,
  };
};

export const ImportarRelatorioOperadora = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(
    `${baseURL}/ImportarRelatorioOperadora`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};

export const UpdateDemandasImportadas = async (data) => {
  const response = await api.post(`${baseURL}/UpdateDemandasImportadas`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};

export const getPortabilidadeFromChip = async (id) => {
  const response = await api.get(`${baseURL}/SearchLastPortabilityFromChip`, {
    params: { id },
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    value: value ? new Demanda(value) : {},
  };
};

export const GeraContratoClickSign = async (data) => {
  const response = await api.post(`${baseURL}/GeraContratoClickSign`, data);
  return response.data ?? {};
};

export const geraTTClickSign = async (data) => {
  const response = await api.post(`${baseURL}/GeraTTClickSign`, data);
  return response.data ?? {};
};

export const getDemandasPaginatedWithClickSignDocumentStatusUpdated = async (
  filters
) => {
  const response = await api.get(`${baseURL}/UpdateStatusDocumentsClickSign`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Demanda(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const criarDemandasBloqueioLinhaInadimplencia = async (
  clienteLinhas
) => {
  const response = await api.post(
    `${baseURL}/CreateDemandasBloqueioInadimplencia`,
    clienteLinhas
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value: value ?? [],
  };
};

export const criarDemandasDesbloqueio = async (nrSeqsClienteLinha) => {
  const response = await api.get(`${baseURL}/CreateDemandasDesbloqueio`, {
    params: { nrSeqsClienteLinha },
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const getOperadoraCurrentCycleStart = async (nrSeqOperadora) => {
  const response = await api.get(`${baseURL}/GetOperadoraCurrentCycleStart`, {
    params: { nrSeqOperadora },
  });

  const { value } = response.data ?? {};

  return value;
};

export const geraBoleto = async (data) => {
  const response = await api.post(`${baseURL}/GeraBoleto`, data);

  const { status, message, value: demanda } = response.data;

  return { status, message, demanda };
};

export const efetivarDemandaSemIntegracaoVivoMvno = async (data) => {
  const response = await api.post(`${baseURL}/EfetivacaoForcada`, data);
  return response.data ?? {};
};

export const refletirDemanda = async (data) => {
  const response = await api.post(`${baseURL}/RefletirDemanda`, data);

  return response.data ?? {};
};
