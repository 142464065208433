import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getEmpresaNrSeqPessoaEmp,
  saveEmpresa,
  deleteEmpresa,
} from 'core/services/SEG/empresa';
import { IEmpresa } from 'core/interfaces/SEG';
// @ts-expect-error
import EmpresaPanel from './empresaPanel.tsx';
import ContatoAdicionalPanel from './contatoAdicionalPanel.tsx';

interface IEmpresaState extends IEmpresa {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Empresa({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const contatosAdicionais = useRef<any>(null);

  const [empresaData, setEmpresaData] = useState<IEmpresaState | any>({
    flgFisica: true,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearContatosAdicionaisPanel = () => {
    contatosAdicionais?.current?.clear();
  };

  const fillContatosAdicionaisPanel = () => {
    contatosAdicionais?.current?.fillGrid();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataContatosGrid = () => contatosAdicionais?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearContatosAdicionaisPanel();
    setEmpresaData({
      flgFisica: true,
      flgAtivo: true,
      vlrPercentualDescontoPagamentos: 0,
      pessoa: {
        nrSeqPessoa: undefined,
      },
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      const retornoEmpresa: IEmpresa | any = await getEmpresaNrSeqPessoaEmp(
        nrSeqPessoa
      );

      if ('nrSeqPessoaEmp' in retornoEmpresa) {
        setEmpresaData({
          ...retornoEmpresa,
          pessoa: { nrSeqPessoa: retornoEmpresa.nrSeqPessoaEmp },
        });

        if (
          retornoEmpresa.contatosAdicionais &&
          retornoEmpresa.contatosAdicionais.length > 0
        ) {
          fillContatosAdicionaisPanel();
        } else clearContatosAdicionaisPanel();
      }
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();
    const contatos = getDataContatosGrid();

    const { status, message: msg, value } = await saveEmpresa({
      ...empresaData,
      ...pessoa,
      flgFisica: pessoa.flgFisica,
      contatosAdicionais: contatos,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteEmpresa(registryKey);

      setEmpresaData(status === ResponseStatus.Success ? {} : empresaData);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Empresa'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={empresaData?.nrSeqPessoaEmp > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={empresaData.pessoa?.nrSeqPessoa}
            data={empresaData}
            setData={setEmpresaData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Empresa',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <EmpresaPanel
                      empresaData={empresaData}
                      setEmpresaData={setEmpresaData}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <ContatoAdicionalPanel
                      empresaData={empresaData}
                      setEmpresaData={setEmpresaData}
                      onSetMessage={onSetMessage}
                      ref={contatosAdicionais}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
