import qs from 'qs';
import Pagination from 'core/models/pagination';
import TituloReceberFatura from 'core/models/FIN/tituloReceberFatura';
import api from '../api';

const base = 'FIN/TituloReceberFatura';

export const postPrintBoletosFaturaTelefone = async (parameters) => {
  const response = await api.post(
    `${base}/PrintFaturaContaTelefone`,
    parameters
  );
  return response.data ?? {};
};

export const postGerarPDFBoleto = async (parameters) => {
  const response = await api.post(`${base}/GerarPDFBoleto`, parameters);
  return response.data ?? {};
};

export const postGerarPDFBoletoTit = async (parameters) => {
  const response = await api.post(`${base}/GerarPDFBoletoTit`, parameters);
  return response.data ?? {};
};

export const getGerarPdfBoleto = async (nrSeqFatura, cdTit) => {
  const response = await api.get(`${base}/GerarPdfBoletoCdTit`, {
    params: { nrSeqFatura, cdTit },
  });
  return response.data ?? {};
};

export const getTituloReceberFaturaList = async (filters) => {
  const response = await api.get(`${base}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceberFatura(json)) ?? [],
  };
};

export const getTituloReceberFaturaPagined = async (filters) => {
  const response = await api.get(`${base}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceberFatura(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getNovoNossoNumero = async (filters) => {
  const response = await api.get(`${base}/OurNewNumber`, {
    params: filters,
  });
  return response.data ?? {};
};

export const getTituloReceberFatura = async (id) => {
  const response = await api.get(`${base}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TituloReceberFatura(value) : {};
};

export const getGerarPdfBoletoByContaRecurso = async (
  noCodigoDownload,
  nrSeqContaRecurso
) => {
  const response = await api.get(`${base}/GerarPdfBoletoByContaRecurso`, {
    params: { noCodigoDownload, nrSeqContaRecurso },
  });

  return response.data ?? {};
};

export const saveTituloReceberFatura = async (data) => {
  const response = await api.post(`${base}/`, data);
  return response.data ?? {};
};

export const postUpdateParcells = async (data) => {
  const response = await api.post(`${base}/UpdateParcells`, data);
  return response.data ?? {};
};

export const getEstornarTituloReceberFatura = async (
  nrSeqFatura,
  flgConfirmaEstorno,
  flgConfirmaEstornoBoletoExcluido
) => {
  const response = await api.get(`${base}/EstornarFatura`, {
    params: {
      nrSeqFatura,
      flgConfirmaEstorno,
      flgConfirmaEstornoBoletoExcluido,
    },
  });
  return response.data ?? {};
};

export const postEnviarFaturas = async (data) => {
  const response = await api.post(`${base}/EnviarFaturas`, data);
  return response.data ?? {};
};

export const postValidaEnviaFaturaEmail = async (data) => {
  const response = await api.post(`${base}/ValidaEnviarFaturas`, data);
  const { status, message, value, pagination } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new TituloReceberFatura(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const deleteTituloReceberFatura = async (id) => {
  const response = await api.delete(`${base}/`, { params: { id } });
  return response.data ?? {};
};

export const printTituloReceberFatura = async (id) => {
  const response = await api.get(`${base}/PrintFatura`, {
    params: { id },
  });

  return response.data ?? {};
};

export const excelTituloReceberFatura = async (filters) => {
  const response = await api.get(`${base}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTituloReceberFatura = async (ids) => {
  const response = await api.delete(`${base}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
