/* eslint-disable no-unused-vars */
import { SearchAniversariantesDoDia } from 'core/services/FIN';
import { getGraficoDemandasDevolvidaInfo } from 'core/services/HELP';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { Button, CSDBarGraphic, GridView, Panel } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

const HomeStep = forwardRef(({ data, setData, setLoading }) => {
  const graficoDemandasRef = useRef();
  const gridViewAniversariantes = useRef();

  const [graficoData, setGraficoData] = useState({});
  const [aniversariantes, setAniversariantes] = useState([]);

  const getGraficoData = async () => {
    const { value: demandas } = await getGraficoDemandasDevolvidaInfo();

    setGraficoData({ demandas });
  };

  const getAniversariantes = async () => {
    const { clientes } = await SearchAniversariantesDoDia();

    const today = new Date();
    const list = clientes.map((item) => {
      const { nrSeqPessoa, noPessoa, noImagemBase64 } = item;

      const dtNascimento = new Date(item.dtNascimento);

      const age = today.getFullYear() - dtNascimento.getFullYear();

      return { nrSeqPessoa, noPessoa, age, noImagemBase64 };
    });

    gridViewAniversariantes?.current?.setDataSource(list);

    setAniversariantes(list);
  };

  useEffect(async () => {
    setLoading(true);

    await getGraficoData();

    await getAniversariantes();

    setLoading(false);
  }, []);

  const optionsBarGraphicDemandas = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { formattedValue } = tooltipItem;

            return `Valor: ${formattedValue}`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  useEffect(() => {
    if (Object.keys(graficoData).length > 0) {
      const labels = graficoData.demandas?.map((p) => p.noMes);

      const demandasDevolvidasData = graficoData.demandas?.map(
        (item) => item.qtdDemandas
      );

      graficoDemandasRef?.current?.setDataSource({
        labels,
        datasets: [
          {
            label: 'Demandas Devolvidas',
            data: demandasDevolvidasData,
            backgroundColor: 'rgba(220, 226, 35, 0.8)',
          },
        ],
      });
    }
  }, [graficoData]);

  return (
    <div className='row'>
      <div className='col'>
        <Panel>
          <Panel.Header title='Home' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <Panel>
                <Panel.Header
                  title='Aniversariantes do Dia'
                  theme={Theme.Primary}
                />
                <Panel.Body>
                  <div className='row mb-3'>
                    {aniversariantes.map((item, index) => (
                      <div className='col-2 mb-3'>
                        <div className='card h-100 justify-content-between align-items-center bg-info border border-warning'>
                          <h6
                            className='card-text my-1'
                            style={{ textAlign: 'center' }}
                          >
                            {item.noPessoa}
                          </h6>
                          <div className='card-body '>
                            <img
                              className='card-img rounded img-fluid'
                              src={item.noImagemBase64}
                              alt='Sem Imagem'
                            />
                            <div className='card-text d-flex justify-content-center align-items-center'>
                              <span className='card-text pt-1 '>
                                Fazendo {item.age} anos!
                              </span>
                            </div>
                          </div>
                          <div className='card-footer w-100'>
                            <Button
                              icon='envelope'
                              outline
                              className='p-0 m-0 h-100 w-50  '
                              theme={Theme.Success}
                              size={BootstrapSizes.Large}
                            />
                            <Button
                              icon='sms'
                              size={BootstrapSizes.Large}
                              outline
                              className='p-0 m-0 h-100 w-50'
                              theme={Theme.Success}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Panel.Body>
              </Panel>
            </div>
            <div className='row'>
              <div className='col'>
                <CSDBarGraphic
                  ref={graficoDemandasRef}
                  options={optionsBarGraphicDemandas}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
});

export default HomeStep;
