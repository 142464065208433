import React, { useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';

import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
  Switch,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  excelFatura,
  getFaturaPagined,
  getFormaPagamentoAutoComplete,
  postValidateAlteraVencimentoDebito,
  postValidateOcorrenciaBoleto,
  printFatura,
  postGerarPDFBoletoTit,
} from 'core/services/FIN';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import ModalAlteraVencimentoDebito from './modalAlteraVencimentoDebito';
import ModalGeraOcorrencia from './modalGeraOcorrencia';

const tipoTitulo = [
  {
    text: 'Todos',
    value: 'T',
  },
  {
    text: 'Aberto',
    value: 'A',
  },

  {
    text: 'Fechado',
    value: 'F',
  },
];

export default function EditarFatura({
  transaction,
  onOpenPage,
  isActive,
  onOpenTransaction,
  findTransaction,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ noStatusFatura: 'T' });
  const [faturas, setFaturas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [
    modalShowAlteraVencimentoDebito,
    setModalAlteraVencimentoDebito,
  ] = useState(false);
  const [modalShowOcorrenciaBoleto, setModalOcorrenciaBoleto] = useState(false);

  const search = async (params = null) => {
    setLoading(true);

    const { data: tituloreceber, pagination } = await getFaturaPagined(
      params ?? filtros
    );

    gridView.current.setDataSource(tituloreceber, pagination);

    setLoading(false);
  };

  const columns = [
    { key: 'nrSeqTituloReceberFatura', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrSeqGrupoEmpresa', title: 'NrSeqGrupoEmpresa', visible: false },
    { key: 'nrSeqEmpresa', title: 'NrSeqEmpresa', visible: false },
    { key: 'cdFatura', title: 'Nr. Fat', sortKey: 'cdFatura' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
      sortKey: 'noPessoa',
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
      sortKey: 'dtVencimento',
    },

    {
      key: 'vlrFatura',
      title: 'Valor',
      format: GridView.DataTypes.Money,
      sortKey: 'vlrFatura',
    },
    { key: 'nrSeqPessoaCli', title: 'NrSeqPessoaCli', visible: false },
    {
      key: 'dtFatura',
      title: 'Data Fatura',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrDesconto',
      title: 'VlrDesconto',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    {
      key: 'vlrAcrescimo',
      title: 'VlrAcrescimo',
      format: GridView.DataTypes.Money,
      visible: false,
    },
  ];

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'EditarFatura/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqTituloReceberFatura = sourceRow.find(
      (e) => e.key === 'nrSeqTituloReceberFatura'
    );
    onOpenMaintenance(nrSeqTituloReceberFatura.value);
  };

  const onPrint = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const value = await printFatura(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {};

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    return formasPagamento;
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onExcel = async () => {
    setLoading(true);
    const value = await excelFatura(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Fatura.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onAlteraVencimentoDebito = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    const nrSeqsFatura = selecteds.map((row) => row[0].value);
    const retorno = await postValidateAlteraVencimentoDebito(nrSeqsFatura);
    if (retorno.status === ResponseStatus.Success) {
      setFaturas(retorno.value);
      setModalAlteraVencimentoDebito(true);
    }
    setLoading(false);
    onSetMessage(retorno.status, retorno.message);
  };

  const onPrintBoletos = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    if (!selecteds || selecteds.length <= 0)
      onSetMessage(ResponseStatus.Error, 'Nenhuma fatura selecionada.');
    else {
      setLoading(true);
      const nrSeqsFatura = selecteds.map((row) => row[0].value);
      const boleto = await postGerarPDFBoletoTit(nrSeqsFatura);

      setLoading(false);
      onOpenReport(transaction.noTransacao, boleto);
    }
  };

  const onGeraOcorrenciaBoleto = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    const nrSeqsFatura = selecteds.map((row) => row[0].value);
    const retorno = await postValidateOcorrenciaBoleto(nrSeqsFatura);
    if (retorno.status === ResponseStatus.Success) {
      setFaturas(retorno.value);
      setModalOcorrenciaBoleto(true);
    }
    setLoading(false);
    onSetMessage(retorno.status, retorno.message);
  };
  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Editar Fatura'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      transaction={transaction}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrint('Geral')}
        />

        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onExcel('Padrao')}
        />
        <ToolbarButtons.Button
          text='Alterar Vencimento Débito'
          onClick={() => onAlteraVencimentoDebito()}
        />
        <ToolbarButtons.Button
          text='Gerar Ocorrência Boletos'
          onClick={() => onGeraOcorrenciaBoleto()}
        />
        <ToolbarButtons.Button
          text='Imprimir Boletos'
          icon='print'
          onClick={() => onPrintBoletos()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-8'>
                <Autocomplete
                  label='Cliente'
                  searchDataSource={onSearchCliente}
                  selectedItem={filtros.cliente}
                  findTransaction={findTransaction}
                  onOpenTransaction={onOpenTransaction}
                  transactionToOpen={TransacationCodes.Cliente}
                  nrseqTransactionToOpen='nrSeqPessoaCli'
                  onSelectItem={(cliente) => {
                    setFiltros({
                      ...filtros,
                      cliente,
                      nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col'>
                <RadioButton
                  label='Tipos'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.noStatusFatura}
                  buttons={tipoTitulo}
                  onChange={(noStatusFatura) => {
                    setFiltros({
                      ...filtros,
                      noStatusFatura,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filtros.empresa}
                  onSelectItem={(empresa) => {
                    setFiltros({
                      ...filtros,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Forma de Pagamento'
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={filtros.formaPagamento}
                  onSelectItem={(formaPagamento) => {
                    setFiltros({
                      ...filtros,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    });
                  }}
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>

              <div className='col'>
                <Textbox
                  maxLength={20}
                  label='Conhecimento'
                  text={filtros.nrDocumento}
                  onChangedValue={(nrDocumento) =>
                    setFiltros({
                      ...filtros,
                      nrDocumento,
                    })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={20}
                  label='Nr. Nota'
                  text={filtros.nrNfv}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(nrNfv) =>
                    setFiltros({
                      ...filtros,
                      nrNfv,
                    })
                  }
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <Textbox
                  maxLength={20}
                  label='Nosso Número'
                  text={filtros.nossoNumero}
                  onChangedValue={(nossoNumero) =>
                    setFiltros({
                      ...filtros,
                      nossoNumero,
                    })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={20}
                  label='Fatura'
                  text={filtros.cdFatura}
                  onChangedValue={(cdFatura) =>
                    setFiltros({
                      ...filtros,
                      cdFatura,
                    })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={20}
                  label='Valor'
                  mask={MaskTypes.DecimalCustom}
                  text={filtros.vlrFatura}
                  onChangedValue={(vlrFatura) =>
                    setFiltros({
                      ...filtros,
                      vlrFatura,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Vencimento De'
                  text={filtros.dtVencimentoIni}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoIni) =>
                    setFiltros({ ...filtros, dtVencimentoIni })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Vencimento Até'
                  text={filtros.dtVencimentoFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoFim) =>
                    setFiltros({ ...filtros, dtVencimentoFim })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Cadastro De'
                  text={filtros.dtCadastroIni}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroIni) =>
                    setFiltros({ ...filtros, dtCadastroIni })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Cadastro Até'
                  text={filtros.dtCadastroFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroFim) =>
                    setFiltros({ ...filtros, dtCadastroFim })
                  }
                />
              </div>
              <div className='col'>
                <Switch
                  formControl
                  label='Filtro CTE'
                  checked={filtros.flgFiltroCte}
                  onChange={async (flgFiltroCte) => {
                    setFiltros({ ...filtros, flgFiltroCte });
                  }}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            transaction={transaction}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onRowDoubleClick={onRowDoubleClick}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <ModalAlteraVencimentoDebito
        show={modalShowAlteraVencimentoDebito}
        faturas={faturas}
        onClose={() => setModalAlteraVencimentoDebito(false)}
        retornoSave={(retorno) => {
          onSetMessage(retorno.status, retorno.message);
          setModalAlteraVencimentoDebito(false);
        }}
      />

      <ModalGeraOcorrencia
        show={modalShowOcorrenciaBoleto}
        faturas={faturas}
        onClose={() => setModalOcorrenciaBoleto(false)}
        retornoSave={(retorno) => {
          onSetMessage(retorno.status, retorno.message);
          setModalOcorrenciaBoleto(false);
        }}
      />
    </CSDSelPage>
  );
}
