/* eslint-disable import/extensions */
import {
  PocOrcamentoProd,
  Prospect,
  PocTipoOrcamento,
  PocPoco,
  PocOrcamentoDocumento,
  PocOrcamentoStatus,
} from 'core/models/POC';
import { Cliente, CondicaoPagamento } from 'core/models/FIN';

import { Vendedor } from 'core/models/VEN';

import { Empresa, Endereco } from 'core/models/SEG';

import ContatoAdicional from 'core/models/SEG/contatoAdicional';

import OrcamentoEndereco from './orcamentoEnrereco';

import EnergiaUnidadeConsumidora from '../ENE/energiaUnidadeConsumidora';
import EnergiaConta from '../ENE/energiaConta';

export default class PocOrcamento {
  nrSeqPocOrcamento: number;

  nrSeqProspect: number;

  nrMetros: number;

  dtProposta: Date;

  dtValidade: Date;

  cdProposta: string;

  dtCad: Date;

  nrSeqPessoaCli: number;

  pocOrcamentoObs: string;

  vlrTotal: number;

  noCondicaoPagamento: string;

  nrPrazoInicioDias: number;

  nrPrazoExecucao: number;

  flgManutencao: boolean;

  nrSeqPocTipoOrcamento: number;

  nrSeqPocOrcamentoStatus: number;

  nrSeqPocPoco: number;

  nrSeqPessoaVen: number;

  solicitante: string;

  email: string;

  noObservacoes: string;

  noObservacaoFinanceiro: string;

  contato: string;

  nrSeqUsuarioCancelamento: number;

  nrOrdem: number;

  noOrdemCompra: string;

  nrSeqPocOrcamentoPai: number;

  dtCancelamento: Date;

  noMotivoCancelamento: string;

  prospect: Prospect;

  tipoOrcamento: PocTipoOrcamento;

  cliente: Cliente;

  contatoAdicional: ContatoAdicional;

  poco: PocPoco;

  nrSeqCondicaoPagamento: number;

  nrSeqEndereco: number;

  nrSeqEmpresa: number;

  produto: PocOrcamentoProd;

  condicaoPagamento: CondicaoPagamento;

  documentos: Array<PocOrcamentoDocumento>;

  orcamentoEnderecos: Array<OrcamentoEndereco>;

  itens: Array<PocOrcamentoProd>;

  status: PocOrcamentoStatus;

  vendedor: Vendedor;

  endereco: Endereco;

  noImagemBase64: string;

  tipoEndereco: number;

  noOrcamentoComplementares: string;

  noOrcamentoOriginal: string;

  noLocalObra: string;

  nrSeqEnergiaUnidadeConsumidora: number;

  energiaUnidadeConsumidora: EnergiaUnidadeConsumidora;

  nrSeqEnergiaConta: number;

  energiaConta: EnergiaConta;

  nrDescontoOfertado: number;

  flgFidelizacao: boolean;

  nrMesesFidelizacao: number;

  auth: string;

  empresa: Empresa;

  dtAprovadoCliente: Date;

  dtEnvioAssinatura: Date;

  dtAssinado: Date;

  constructor(jsonObject: PocOrcamento) {
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.nrSeqProspect = jsonObject.nrSeqProspect;
    this.nrMetros = jsonObject.nrMetros;
    this.dtProposta = jsonObject.dtProposta;
    this.dtValidade = jsonObject.dtValidade;
    this.cdProposta = jsonObject.cdProposta;
    this.dtCad = jsonObject.dtCad;
    this.pocOrcamentoObs = jsonObject.pocOrcamentoObs;
    this.vlrTotal = jsonObject.vlrTotal;
    this.noCondicaoPagamento = jsonObject.noCondicaoPagamento;
    this.nrPrazoInicioDias = jsonObject.nrPrazoInicioDias;
    this.nrPrazoExecucao = jsonObject.nrPrazoExecucao;
    this.flgManutencao = jsonObject.flgManutencao;
    this.nrSeqPocTipoOrcamento = jsonObject.nrSeqPocTipoOrcamento;
    this.nrSeqPocOrcamentoStatus = jsonObject.nrSeqPocOrcamentoStatus;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.status = new PocOrcamentoStatus(jsonObject.status ?? {});
    this.prospect = new Prospect(jsonObject.prospect ?? {});
    this.tipoOrcamento = new PocTipoOrcamento(jsonObject.tipoOrcamento ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.poco = new PocPoco(jsonObject.poco ?? {});
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.nrSeqEndereco = jsonObject.nrSeqEndereco;
    this.solicitante = jsonObject.solicitante;
    this.email = jsonObject.email;
    this.contato = jsonObject.contato;
    this.noObservacoes = jsonObject.noObservacoes;
    this.noObservacaoFinanceiro = jsonObject.noObservacaoFinanceiro;
    this.nrSeqUsuarioCancelamento = jsonObject.nrSeqUsuarioCancelamento;
    this.dtCancelamento = jsonObject.dtCancelamento;
    this.noMotivoCancelamento = jsonObject.noMotivoCancelamento;
    this.noImagemBase64 = jsonObject.noImagemBase64;
    this.tipoEndereco = jsonObject.tipoEndereco;
    this.nrOrdem = jsonObject.nrOrdem;
    this.nrSeqPocOrcamentoPai = jsonObject.nrSeqPocOrcamentoPai;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.noOrdemCompra = jsonObject.noOrdemCompra;
    this.noOrcamentoComplementares = jsonObject.noOrcamentoComplementares;
    this.noOrcamentoOriginal = jsonObject.noOrcamentoOriginal;
    this.noLocalObra = jsonObject.noLocalObra;
    this.contatoAdicional = new ContatoAdicional(
      jsonObject.contatoAdicional ?? {}
    );
    this.produto = new PocOrcamentoProd(jsonObject.produto ?? {});
    this.endereco = new Endereco(jsonObject.endereco ?? {});
    this.condicaoPagamento = new CondicaoPagamento(
      jsonObject.condicaoPagamento ?? {}
    );

    this.vendedor = new Vendedor(jsonObject.vendedor ?? {});

    if (jsonObject.documentos) {
      this.documentos = jsonObject.documentos?.map(
        (documento: PocOrcamentoDocumento) =>
          new PocOrcamentoDocumento(documento ?? {})
      );
    } else {
      this.documentos = [];
    }

    this.itens =
      jsonObject.itens?.map(
        (json: PocOrcamentoProd) => new PocOrcamentoProd(json)
      ) ?? [];
    this.orcamentoEnderecos = (jsonObject.orcamentoEnderecos ?? []).map(
      (oend) => new OrcamentoEndereco(oend)
    );
    this.nrSeqEnergiaUnidadeConsumidora =
      jsonObject.nrSeqEnergiaUnidadeConsumidora;
    this.energiaUnidadeConsumidora = new EnergiaUnidadeConsumidora(
      jsonObject.energiaUnidadeConsumidora ?? {}
    );
    this.nrSeqEnergiaConta = jsonObject.nrSeqEnergiaConta;
    this.energiaConta = new EnergiaConta(jsonObject.energiaConta ?? {});
    this.nrDescontoOfertado = jsonObject.nrDescontoOfertado;
    this.flgFidelizacao = jsonObject.flgFidelizacao;
    this.nrMesesFidelizacao = jsonObject.nrMesesFidelizacao;
    this.auth = jsonObject.auth;
    this.empresa = jsonObject.empresa;
    this.dtAprovadoCliente = jsonObject.dtAprovadoCliente;
    this.dtEnvioAssinatura = jsonObject.dtEnvioAssinatura;
    this.dtAssinado = jsonObject.dtAssinado;
  }

  toJson = () => JSON.stringify(this);
}

export const StatusOrcamento = {
  Aguardando_Aprovacao_Cliente: 1,
  Aguardando_Faturamento: 2,
  Aprovado: 3,
  Encerrado: 4,
  Faturado: 5,
  Faturado_Parcial: 6,
  Cancelado: 7,
  Aguardando_Aprovacao_Desconto: 8,
  DescontoExcedido: 9,
  Revisado: 10,
  Reprovado: 11,
  Aguardando_Envio_Contrato_Assinatura: 12,
  Contratos_Assinados: 13,
  Enviado_Para_Assinatura_Digital: 14,
};
