import React from 'react';
import { Theme } from 'ui/Helpers/enums';
import { BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import {
  Modal,
  Notification,
  Button,
  Textbox,
  DatePicker,
} from 'ui/components';
import { getVeiculoRodagemAtiva } from 'core/services/FRO/veiculoRodagem';
import { IVeiculoRodagem } from 'core/interfaces/FRO/veiculoRodagem';

interface IData {
  nrSeqVeiculoRodagem?: number;
  nrSeqVeiculo?: number;
  nrSeqVeiculoVinc?: number;
  confirmUpdate?: boolean;
  nrOdometroInformado?: number;
  dtInicioInformado?: string;
  nrOdometroInicial?: number;
  dtInicio?: string;
  veiculo?: any;
  veiculoVinc?: any;
  veiculoRodagem?: IVeiculoRodagem;
}

interface IProps {
  show: boolean;
  vinculo: IVeiculoRodagem;
  setLoading: any;
  onClose: (res: IData) => void;
}

export function ModalEditarVeiculoVinculo({
  show,
  vinculo,
  setLoading,
  onClose,
}: IProps) {
  const [message, setMessage] = React.useState<any>({});
  const [data, setData] = React.useState<IData>({ confirmUpdate: false });

  const onSetMessage = (status: number, msg: string) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = async () => {
    setLoading(true);

    const response = await getVeiculoRodagemAtiva({ ...vinculo });

    if (response.status === ResponseStatus.Error)
      return onSetMessage(
        response.status,
        'Erro ao buscar o vínculo do veículo'
      );

    const veiculoRodagem: IVeiculoRodagem = response.data;

    setData({
      ...data,
      nrSeqVeiculoRodagem: veiculoRodagem.nrSeqVeiculoRodagem,
      nrSeqVeiculo: vinculo.nrSeqVeiculo,
      nrSeqVeiculoVinc: vinculo.nrSeqVeiculoVinc,
      dtInicioInformado: veiculoRodagem.dtInicio,
      dtInicio: veiculoRodagem.dtInicio,
      nrOdometroInformado: veiculoRodagem.nrOdometroInicial,
      veiculo: veiculoRodagem.veiculo,
      veiculoVinc: veiculoRodagem.veiculoVinc,
      veiculoRodagem,
    });

    setLoading(false);

    return 0;
  };

  React.useEffect(() => {
    if (show) {
      load();
    }
  }, [show]);

  const handleOnClose = (confirmUpdate: boolean) => {
    const obj = { ...data, confirmUpdate };
    setData({ confirmUpdate: false });
    onClose(obj);
  };

  const validaUpdateVinculo = (validaData: IData) => {
    let isValid = true;
    let validaMessage = '';

    if (!validaData.dtInicio) {
      isValid = false;
      validaMessage = 'Data de Início não informado.';
    }

    if (!validaData.nrOdometroInicial) {
      isValid = false;
      validaMessage = 'Odômetro de Início não informado.';
    }

    return { isValid, validaMessage };
  };

  return (
    //  @ts-expect-error
    <Modal
      show={show}
      title={`Atualizar vínculo do veículo ${data?.veiculo?.placa}`}
      onClose={() => handleOnClose(false)}
      size={BootstrapSizes.Large}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        {message && (
          <>
            <div className='row mb-3'>
              <div className='col'>
                {/* @ts-expect-error */}
                <Notification
                  message={message.message}
                  theme={message.theme}
                  onClose={() => setMessage(null)}
                />
              </div>
            </div>
          </>
        )}

        <div className='row mb-3 align-items-end'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Odômetro informado:'
              readOnly
              text={data?.nrOdometroInformado}
            />
          </div>
          <div className='col'>
            {/* @ts-expect-error */}
            <DatePicker
              label='Data de Início informado:'
              readOnly
              text={data?.dtInicioInformado}
            />
          </div>
        </div>

        <div className='row mb-3 align-items-end'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Odômetro do Veículo Principal'
              maxLength={12}
              text={data?.nrOdometroInicial}
              onChangedValue={(nrOdometroInicial: number) =>
                setData({ ...data, nrOdometroInicial })
              }
            />
          </div>
          <div className='col'>
            <DatePicker
              label='Data Inicial'
              text={data?.dtInicio}
              onChange={(dtInicio: string) => setData({ ...data, dtInicio })}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Danger}
          onClick={() => {
            const { isValid, validaMessage } = validaUpdateVinculo(data);
            if (isValid) {
              handleOnClose(true);
            } else {
              onSetMessage(ResponseStatus.Error, validaMessage);
            }
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
